body,
p ,
.font-default {
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
}
.h1-sm,
.h1-md,
.h1-lg {
  margin: 0;
  font-weight: bold;
  display: inline;
}

.h1-sm {
  font-size: 1em;
}
.h1-md {
  font-size: 1.5em;
}
.h1-lg {
  font-size: 2em;
}

.h1-sm,
.h1-md,
.h1-lg {
  margin: 0;
  font-weight: bold;
  display: inline;
}

.h1-sm {
  font-size: 1em;
}
.h1-md {
  font-size: 1.5em;
}
.h1-lg {
  font-size: 2em;
}

h3 {
  font-size: 24px;
  font-family: "Alfa Slab One", serif;
}

h4,
.h4 {
  font-size: 1.4rem;
}

a{
  color: #212529;
  text-decoration: none;
}

a:link,
a:hover {
  text-decoration: none;
  /* color: initial; */
}

a.underline-link {
    color: #005ebd;
    text-decoration: underline;
}

.trade {
  font-size: 24px;
  line-height: 36px;
  font-family: "Oswald-Bold", sans-serif;
  /* font-weight: bold; */
}

.font-trade {
  font-family: "Oswald-Regular", sans-serif;
  /*font-weight: bold;*/
}

.font-trade1 {
  font-family: "Oswald-Regular", sans-serif;
}

.font-stymie {
  font-family: "Alfa Slab One", serif;
}

.font-weight-xbold {
  font-weight: 800;
}

.text-1 {
  font-size: 1rem;
  line-height: inherit;
}

.text-2 {
  font-size: 2.4rem;
  line-height: inherit;
}

.text-xlarge {
  font-size: 48px;
}

.text-large {
  font-size: 31px;
}

.text-md {
  font-size: 24px;
}

.text-facebook {
  color: #3b5998;
}

.text-sm {
  font-size: 18px;
}

.text-default {
  font-size: 14px;
}

.text-wrap {
  white-space: normal;
}

.text-nowrap {
  white-space: nowrap;
}

.text-white {
  color: #fff;
}

.text-default-grey {
  color: #333;
}

.text-dk-grey {
  color: #5f6d6e;
}

.text-grey {
  color: #a7a7a7;
}

.text-lt-grey {
  color: #d8d8d8;
}

.text-youtube {
  color: #e62117;
}

.text-gplus {
  color: #db4437;
}

.text-pinterest,
.text-red {
  color: #bd081c;
}

.text-insta {
  color: #262626;
}

.text-primary {
  color: #265787 !important;
}

.text-strike {
  text-decoration: line-through;
}

.text-condensed {
  font: 300 22px/40px "Oswald-Regular", sans-serif;
}

.radius-0 {
  border-radius: 0 !important;
}

.header-icon {
  font-size: 20px;
  color: #a7a7a7;
}

.p-cursor {
  cursor: pointer;
}

.p-t120 {
  padding-top: 120px;
}

.pb-1 {
    word-break: break-all;
}

.pdp-container .pb-1 {
  overflow-wrap: break-word;
  word-break: break-word;
}
.link-hover:hover {
  text-decoration: underline;
}

.no-outline {
  outline: none;
  cursor: pointer;
}

.l-height-33 {
  line-height: 33px;
}

.img {
  width: 100%;
  height: auto;
}

.badge {
  border-radius: 0;
  border: 1px solid #ced4da;
  padding: .25em .4em;
}

.bg-image {
  height: 80px;
  background: url("//media.tractorsupply.com/is/image/TractorSupplyCompany/20150508-tsc-footer-bg?$2880$");
}

.bg-grey-img {
  background:#fff;
}

.bg-inverse {
  background-color: #171717;
}

.bg-trans {
  background-color: transparent;
}

footer.bg-image {
  height: 60px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-40 {
  margin-top: 40px;
}

/*.mt-42 {
  margin-top: 45px;
}*/

.mt-80 {
  margin-top: 80px;
}

.mt-120 {
  margin-top: 103px;
}

.mb-120 {
  margin-bottom: 120px;
}

.border-0 {
  border: none;
}

.border-separate {
  border-top: 1px solid #000;
}

.border-dk {
  border-color: #212121;
}

.border-grey {
  border-color: #d7d7d7;
}

.border-lt-grey {
  border-color: #ced4da;
}

.border-md-grey {
  border-color: rgba(132, 131, 131, 0.22);
}

.border-danger {
  border-color: #dc3545;
}

.border-bottom {
  border-bottom: 1px solid #c6c6c6;
}

.border-top {
  border-top-style: solid;
  border-width: 1px;
}

.border-right {
  border-right-style: solid;
  border-width: 1px;
}

.border-right-2 {
  border-right-style: solid;
  border-width: 2px;
}

.border-left {
  border-left-style: solid;
  border-width: 1px;
}

.border-no {
  border-right-style: solid;
  border-width: 0;
  border-right: none !important;
}

.bg-grey {
  background-color: #f4f1ec;
}

.bg-grey-hover {
  background-color: #fff;
}

.bg-grey-hover:hover {
  background-color: #f4f1ec;
}

.bg-white {
  background-color: #fff;
}

.bg-ltgrey {
  background-color: #e5e2d9;
}

.bg-lter-grey {
  background-color: #f3f2ed;
}

.font-bold {
  font-weight: bold;
}

.bg-hover-grey:hover {
  background-color: #a09a9a;
}

.bg-black {
  background-color: #000;
}

.width100 {
  width: 100px;
}

.w-80 {
  width: 80%;
}

.w-90 {
  width: 90%;
}

.bg-white {
  background: #fff;
}

.text-decorate {
  text-decoration: underline !important;
}

img {
  max-width: 100%;
  height: auto;
}

.img-nopad {
  top: 0;
  right: 0;
  position: relative;
  height: 600px;
  max-width: 300px;
  width: 100%;
}

.home-ol-img {
  width: 100%;
  height: 145px;
}

.imgdiv-hm {
  float: left;
  position: relative;
  width: 44%;
  height: 173px;
  margin-top: 13px;
  margin-left: 4%;
}

.show,
.show > .dropdown-menu,
.dropup.open > .dropdown-menu {
  display: block;
  /* background-color: transparent; */
}

.nopad {
  padding: 0 !important;
}

.dropup .dropdown-menu {
  border: 0;
  padding: 0 2rem 0 2rem;
  height: 247px;
  min-width: 1440px;
  top: -287px;
}

.dropup .dropdown-menu.up2 {
  left: -260px;
}

.dropup .dropdown-menu.up3 {
  left: -1212px;
}

/*
.dropup, .dropdown {
  position: static;
}*/

.dropup .dropdown-toggle a {
  color: #fff;
}

.trade.text-white.dropdown-toggle:hover {
  color: #4e7932;
  text-decoration: none !important;
  /* padding: 0 2rem; */
}

.card-block {
  padding: .8rem;
}

.card,
.card-body {
  border-radius: 0;
  padding: .55rem;
}

.list-group-item:first-child {
  border-radius: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.card-text {
  font: 14px/28px  'Roboto', sans-serif;
  margin: 0;
}

.list-group-item {
  padding: 1rem 1.25rem;
}

#tsc-search-results {
  z-index: 5000;
  font-size: 14px;
  text-decoration: none;
  text-align: left;
  background: #fff;
  border-right: 1px solid #cfcfcf;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 58px;
  left: 5px;
  width: 99%;
}

#tsc-search-results ul {
  list-style: none;
}

.tsc-brand-row {
  background-color: #f5f6f7 !important;
}

.userEnteredChar {
  font-weight: bold;
}

.img-banner {
  /* width: 30%; */
  /* margin-left: 5px; */
}

.img-banner img {
  /* width: 100%; */
  /* max-width: 400px; */
}

.input-group-btn:not(:first-child) > .btn,
.input-group-btn:not(:first-child) > .btn-group {
  margin-left: 0;
}

.dropdown-menu.megamenu,
.dropdown-menu.megamenu-2,
.dropdown-menu.minimenu {
  padding: 20px 0;
  min-width: auto;
  width: 100%;
  right: 0;
  left: 0;
  top: inherit;
  font: 14px  'Roboto', sans-serif;
  transform: translate3d(0, 41px, 0px) !important;
}

.dropdown-menu.megamenu.stores.show {
  box-shadow: 0 100vh 0 100vh rgba(255, 255, 255, .75), 0 8px 12px rgba(80, 80, 80, 0.75);
  width: 100vw;
  border-width: 1px 0 0;
  border-radius: 0;
  margin-left: -1px;
}

.dropdown-menu.megamenu {
  transform: translate3d(0px, 45px, 0px) !important;
  box-shadow: 0px 3px 5px #757575;
}

.dropdown.minimenu {
  position: relative;
}

.dropdown-menu.signin {
  min-width: 120px;
  padding: 0;
  transform: translate3d(-44px, 39px, 0) !important;
  font: normal 14px/26px "Oswald-Regular", sans-serif;
}

.dropdown.minimenu .dropdown-menu div {
  font-size: 14px;
  border-bottom: 1px solid #c8c0c0;
  padding: 10px;
}

.dropdown.minimenu .dropdown-menu div:last-of-type {
  border-bottom: none;
}

.dropdown.minimenu .dropdown-menu div a:hover {
  text-decoration: none;
  color: inherit;
}

.dropdown-menu.megamenu-2 {
  width: 1100px;
}

.dropdown-menu.megamenu h5 {
  font-size: 1.2em;
  font-family: "Oswald-Bold", sans-serif;
  color: #333;
  letter-spacing: 0.02em;
}

.dropdown-menu.megamenu p,
.dropdown-menu p a,
.dropdown-menu.minimenu li a {
  padding: 0;
  margin: 0 0 5px 0;
  font: 14px  'Roboto', sans-serif;
}

.dropdown-menu.minimenu li {
  padding: 10px 0;
  border-bottom: 1px solid #c8c0c0;
}

#city_overlay,
#zipcode_overlay {
  height:38px;
}

.nav-link {
  padding: .3em 1em .1em .43em;
}

.nav-link svg,
.nav-link use {
  pointer-events:none;
}

.table-striped tbody tr:nth-of-type(even) {
  background-color: #f3f2ec;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #e4e2d9;
}

.navbar {
  padding: 0;
}

.navbar-toggleable-md .navbar-nav .nav-link {
  padding-right: .8rem;
  padding-left: 0;
}

.dropable.navbar-nav {
  text-align: right;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
}

.space-between {
  justify-content: space-between;
}

.second-nav {
  line-height: 26px;
  font-size: 15px;
  font-family: "Oswald-Regular", sans-serif;
  letter-spacing: 0.02em;
}

.dropdown-toggle :after,
.navbar .dropdown-toggle:after,
.nav .dropdown-toggle:after {
  display: none;
}

.second-nav.dropdown-toggle span {
  font-size: 20px;
  position: relative;
  left: 5px;
}

.fixedtop {
  /* min-height: 300px; */
}

.drop-full {
  min-width: 750px;
  max-width: 100%;
  padding: 15px;
  right: 0;
  left: 0;
}

.dropable li a {
  font-size: 16px;
  padding: .5rem 0;
}

.position-static {
  position: static !important;
}

input[type="text"].search-text {
  /* width: 100%; */
  height: 56px;
  padding: 16px 30px 16px 16px;
  box-sizing: border-box;
  font: 24px "Oswald-Regular";
  color: #b2b2b2;
  border-top: none;
  border-right: none;
  border-bottom: none;
  left: 0;
  letter-spacing: 0.02em;
}

#search_txtBox {
   border-bottom: 1px solid #d6d6d6;
}

.navbar-toggleable-md .dropdown-mega-menu,
.navbar-toggleable-md .navbar-main .dropdown-mega-menu,
.navbar-toggleable-md.navbar-main .dropdown-mega-menu,
.navbar-toggleable-md .navbar-main .dropdown-mega-menu,
.navbar-toggleable-md.navbar-main .dropdown-mega-menu {
  position: static;
}

.dropdown {
  position: static;
}

.hidden {
  display: none;
}

.hide-class {
  display: none !important;
}

.hide  {
  display: none;
}

.sidebar-nav .collapse.show {
  position: absolute;
  left: 0;
  width: 220px;
  -webkit-animation: slide 0.5s forwards;
  -webkit-animation-delay: 2s;
  animation: slide 0.5s forwards;
  animation-delay: 2s;
}

.navbar-left {
  z-index: 555;
  width: 320px;
  background-color: #e5e2d9;
  color: #333;
  opacity: 1.0;
  padding: 2%;
  padding-left: 35px;
  position: relative;
  font-size: 1.4em;
  font-weight: normal;
}

.navbar-left a {
  color: #333;
  text-decoration: none;
}

.ul-top-level {
  list-style-type: none;
  margin-left: -36px;
  font-family: "Oswald-Bold", sans-serif;
  font-size: 24px;
  font-weight: 400;
  color: #231f20;
  border-top: 0;
  margin-bottom: 0;
}

.ul-top-level li {
  line-height: 41px;
}

.ul-2nd-level li {
  margin-left: 0px;
}

.link-2lvl:last-child {
  border-bottom: none;
}

.ul-2nd-level {
  list-style-type: none;
  margin-left: 10px;
  padding-left: 0;
  /* margin-top: 15px !important;*/
}

.ul-2nd-level li {
  padding-left: 15px;
}

.ul-2nd-level li {
  padding-left: 15px !important;
}

.svgimageexplore,
.ul-2nd-level,
.ul-3rd-level,
.link-btn.hide {
  display: none;
}

.ul-3rd-level li {
  margin-left: -42px !important;
  background-color: #f2f1ec;
  font-weight: 100;
  /* padding-left: 38px; */
}

.ul-embedded li {
  margin-left: -80px;
  border: none;
}

.ul-embedded li:last-of-type {
  margin-bottom: 12px;
}

.navbar-left ul li {
  border-bottom: 1px solid #c8c0c0;
  padding-left: 0;
  margin-left: -35px;
  padding-left: 10px;
}

#knowhow_menu #ExploreOutHereOverlay .ul-2nd-level li {
  border-bottom: none !important;
  margin-left: -12px !important;
}

.navbar-left ul li .ul-2nd-level li {
  margin-left: -22px !important;
  padding-left: 21px !important;
}

#rm-top {
  margin-left: -15px;
}

.resp-right-pointer,
.sub-resp-right-pointer {
  font-size: 1.3em;
  position: absolute;
  right: 16px;
}

.resp-right-inner-pointer {
  font-size: 1.5em;
  float: right;
}

.resp-left-pointer {
  font-size: 1.3em;
  position: relative;
  top: 3px;
  right: 8px;
  display: none;
}

.icon-plus2,
.icon-minus2 {
  position: absolute;
  right: 16px;
  margin-top: 8px;
  color: #333;
  font-size: 1.3em;
  color: #5f6d6e;
}

div > ul > li > ul > li.link-2lvl {
  margin-left: 20px;
  width: 320px;
}

.link-1lvl-inner {
  display: none;
}

.link-2lvl-inner {
  background-color: #f2f1ec;
  width: 320px;
  height: auto;
  margin: -15px 0px 0px -38px;
  padding-left: 58px;
}

.ul-3rd-level {
  margin: 0px 0px 0 -39px;
  width: 327px;
  list-style-type: none;
  /* padding-left: 48px; */
}

.ul-top-level-menu li:last-of-type {
  border-bottom: none;
}

.ul-3rd-level li:last-of-type {
  border-bottom: none;
}

#deptMenu .ul-3rd-level {
  margin: 0px 0px 0 -39px;
  width: 336px;
}

#deptMenu .ul-3rd-level li {
  padding-left: 35px !important;
}

#deptMenu .ul-3rd-level li a {
  color: #666;
}

.fa-icon-size {
  font-size: 33px;
}

.form-control {
  border-radius: 0;
  margin-right: 0;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #a7a9af;
  box-shadow: 0 0 0 0.2rem rgba(167, 169, 175, 0.25);
}

.btn-search {
  min-width: 64px;
  border-top: 1px solid #dc3545;
  border-radius: 0 !important;
  border-image-width: 0;
}

.btn-secondary {
  border: none;
}

.btn-sm {
  max-width: 100px;
}

.btn,
.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary.focus {
  border: none;
  box-shadow: none;
  border-radius: 0;
}

.owl-carousel btn {
  padding: inherit 8px;
}

.product_label {
  margin-top: 20px;
  margin-bottom: 0;
  word-wrap: break-word;
  letter-spacing: 0;
  font-weight: bolder;
  font-size: 28px;
  font-family: 'Roboto', sans-serif;
  color: #333;
}

.form-inline:hover {
  border: #fff;
}

.nav-link span h4 {
  font-size: 24px;
}

.slimScrollDiv {
  border: 1px solid #eee;
}

.dropdown-menu {
  font-family: "Oswald-Bold", sans-serif;
  padding: 1em;
  line-height: 1.3em;
  margin: 0;
  font-weight: normal;
  font-size: 1.2em;
  min-width: 220px;
  border-radius: 0;
}

.navbar-toggleable-md .navbar-main .dropdown-mega-menu,
.navbar-toggleable-md.navbar-main .dropdown-mega-menu {
  position: static;
}

footer .dropdown-menu a {
  color: #231f20;
  font-size: 1.1rem;
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
  line-height: 24px;
}

footer .dropdown-menu a:hover,
.dropdown-item:focus,
.dropdown-item:hover,
.dropdown-item.active,
.dropdown-item:active {
  background-color: transparent;
  color: #d20000;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: none;
}

button ,
.btn,
.button {
  display: inline-block;
  height: 40px;
  border: none;
  border-radius: 4px;
  white-space: nowrap;
  text-decoration: none;
  text-transform: none;
  letter-spacing: 0 !important;
  font: 16px  'Roboto', sans-serif;
  color: #fff;
  cursor: pointer;
  max-width: 300px !important;
  width: 100%;
}


.btn.btn-success,
button.green,
button.new_green_btn,
button.green_searchgrid,
button.sl_green_btn,
button.green_searchgrid_done {
  border: 1px solid #446621;
  background: rgba(0, 0, 0, 0) -moz-linear-gradient(90deg, #649430 0%, #69ba04 100%) repeat scroll 0 0;
  background-image: -webkit-linear-gradient(90deg, rgb(100, 148, 48) 0, rgb(105, 186, 4) 100%);
  background-image: -ms-linear-gradient(90deg, rgb(100, 148, 48) 0, rgb(105, 186, 4) 100%);
  font-weight: bold;
}

button.gray,
button.gray_searchgrid,
button.grey,
a.button.gray,
.btn.btn-secondary,
.btn.btn-muted {
  border: 1px solid #cfd0d0;
  background-image: -moz-linear-gradient(90deg, rgb(218, 218, 219) 0, rgb(252, 250, 250) 100%);
  background-image: -webkit-linear-gradient(90deg, rgb(218, 218, 219) 0, rgb(252, 250, 250) 100%);
  background-image: -ms-linear-gradient(90deg, rgb(218, 218, 219) 0, rgb(252, 250, 250) 100%);
  color: #005ebd;
  line-height: 37px;
}

button.gray:hover,
a.button.gray:hover,
button.gray_searchgrid:hover {
  background-image: -moz-linear-gradient(90deg, #e0e0e2 0, #fff 100%);
  background-image: -webkit-linear-gradient(90deg, #e0e0e2 0, #fff 100%);
  background-image: -ms-linear-gradient(90deg, #e0e0e2 0, #fff 100%);
  border-color: #779bc0;
}

.line-h-24 {
  line-height: 25px;
}


#hp_container {
  overflow: hidden;
}


.modal-content {
  font-family: 'Roboto', sans-serif;
  border-radius: 0;
  border: 1px solid #000;
}

.modal-content a {
  color: #005ebd;
}

.modal-title {
  text-transform: uppercase;
  font-weight: normal;
  font-size: 18px;
  font-family: "Alfa Slab One", serif;
  color: #fff;
  letter-spacing: 0.05em;
  margin: 5px 0;
}

.fade.show {
  padding-right: 0 !important;
  outline:none !important;
}

button.close {
  background-color: #d6ded7;
  color: #000;
  padding: 5px;
  width: 32px;
  height: 32px;
  right: 9px;
  margin-top: -33px;
  position: absolute;
  border: none;
  opacity: 1;
  border-radius: 0;
  text-shadow: none;
}

button.close span {
  font-size: 40px;
  line-height: 25px;
  color: #000;
}

.modal-header {
  background-color: #000;
  color: #fff;
  padding: 0;
  text-align: center;
  display: block;
}

.modal-body h2{
    font-size: 2em;
}

.modal-body h4 {
    padding: 15px 0 25px;
    font-size: 1.5em;
}

.modal-body h5 {
  font-weight: normal;
  /*font-size: 18px;
  font-family: "Oswald-Bold", sans-serif;*/
}

.modal-body p {
  font-size: 13px;
  font-family: 'Roboto', sans-serif;
  margin-bottom: .2em;
}

.modal-body a {
  text-decoration: underline;
}

input.zip,
#zipCode_id {
  /* padding: 4px; */
  /* margin: 0 5px; */
  width: 100%;
}

.box-error {
    margin-top: 1em;
    padding: 10px;
    width: auto;
    font:bold 14px 'Roboto', sans-serif;
    background-color: white;
}

input {
  border: 1px solid #c6c6c6;
  display: inline;
  font-size: 16px;
  height: 38px;
  line-height: 40px;
  padding: 0 0 0 10px;
  margin-right: 10px;
  width: 100%;
}

.circle1 {
  width: 35px;
  height: 35px;
  border: 1px solid #ccc;
  border-radius: 50%;
  line-height: 27px;
}

.product-img {
  max-width: 90px;
  padding: 10px;
}
.footer,
#footerDisplay {
    position: relative;
  bottom: 0;
    height: 100%;
  width: 100%;
  line-height: 60px;
    background-color: #464646;
}

footer h4 {
  color: #69913d;
  font: normal 18px  'Roboto', sans-serif;
  line-height: 30px;
}

.container-lg {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  padding-right: 15px;
  padding-left: 15px;
}

.nav-second {
  height: 45px;
  position: relative;
  align-content: space-evenly;
}

.nav-second.container-lg {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.navbar-toggler.navbar-toggler-md:hover,
.navbar-toggler.navbar-toggler-md:active {
  outline: none;
  cursor: pointer;
}

.navbar-toggler-icon {
  font-size: 1.3rem;
}

.navbar-toggler.navbar-toggler-md {
  display: none;
}

.navbar-toggleable-sm .navbar-nav .nav-link h4 {
  font-size: 22px;
}

.navbar-toggleable-sm .navbar-nav .nav-link {
  padding: .38rem .5rem;
}

footer.hidden-md-up a.trade.text-muted {
  font-size: 18px;
}

footer.hidden-md-up .col-12 {
  padding: .25rem 1.5rem !important;
}

.container-fluid {
  /* padding-left: 0; */
  /* padding-right: 0; */
}

.search-wrapper {
  width: 40%;
}

.inner-image {
  display: inline-block;
  padding-top: 42%;
  height: 0;
}

.tsc-logo {
  display: inline-block;
  width: 220px;
  height: 80px;
  margin: 14px 0 0 0;
  font-size: 0;
  line-height: 0;
  background: url("//media.tractorsupply.com/is/image/TractorSupplyCompany/2016%2Dtsc%2Dlogo%2Dreversed?$200PNG$") no-repeat left center;
}

.overlay {
  position: absolute;
  left: 0;
  top: 120px;
  display: none;
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0.6;
  z-index: 100;
}

.overlay-dk {
  display: none;
  position: fixed;
  top: 120px;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000;
  filter: alpha(opacity=80);
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
}

#mystoreDropdown.dropdown.show > .container-lg .overlay {
  display: block;
}

@media (min-width: 576px) {
  .form-inline .input-group {
    width: 100%;
  }
}

.top {
  position: absolute;
  /* transform: translate3d(0, 60px, 0);
    animation-timing-function: ease-in; */
}

.checked {
  color: orange;
}

.normal-text {
  font: normal .9rem/1.4rem 'Roboto', sans-serif;
  display: block;
}

a.normal-text:hover {
  text-decoration: none;
}

.dropdown-menu {
  z-index: 1000;
}

.dropdown-toggle.dropdown-item.nav-link,
.dropdown-item {
  padding: 0;
  width: 100%;
  display: inline-block;
}

.dropdown-mega-wrapper {
  width: 100%;
  max-width: 1440px;
  top: 0;
  left: 0;
  overflow: hidden;
  position: relative;
}

.dropdown-mega-wrapper.show {
  display: block;
  background-color: #F3F3F3 !important;
  z-index: 999;
}

.dropup .dropdown-toggle::after {
  display: none;
}

/***** explore refresh area ******/
#explore-menu {
  position: relative;
  top: 0;
  float: left;
  height: auto;
  background-color: #F3F3F3 !important;
  font-size: 14px;
  line-height: 24px;
  z-index: 5000;
  left: 186px;
}

#LifeOutHerelist {
  width: 260px !important;
  min-height: 600px !important;
  margin: 0 !important;
}

#ExploreOutHereOverlay {
  background-color: #F3F3F3 !important;
  z-index: 111;
}

#LifeOutHerelist .ul-2nd-level {
  display: block;
  margin-left: -37px;
}

.content_column {
  width: 60% !important;
}

.content_column:nth-of-type(2) {
  width: 40% !important;
}

div#ExploreOutHereOverlay ul {
  margin-top: 15px !important;
}

.explore_list_cont {
  width: 69% !important;
  background: transparent !important;
}

.content_column a {
  color: #d20000;
}

.content_column img {
  width: 100%;
  margin: 3% 0 3% 0;
  display: block;
}

.content_column p {
  line-height: 18px;
  margin: 0 0 7px 0;
}

/**********  end explore refresh area flyout ************/

.container.megamenu {
  width: 1200px;
}

.banner1 {
  background: url("../images/tsc-neighbors-club-global.jpg");
  max-width: 1440px;
  width: 100%;
  height: 100px;
}

.hero {
  background: url("../images/-tsc-hero3.jpg");
  width: 1440px;
  max-height: 600px;
}

.container-nav.bg-inverse {
  display: block;
}

.container-nav {}

a.nav-link:active {
  outline: none;
}

.link-1lvl ul li:last-of-type {
  border-bottom: none !important;
}

.new-menu-outer {
  width: 100%;
  margin: 0;
  padding: 0 !important;
  display: none;
}

.menu-content {
  display: none;
}

@media (max-width: 1075px) {
  .content_column:nth-of-type(2) {
    width: 33% !important;
  }

  #LifeOutHerelist {
    width: 230px !important;
  }
}

/*********-- Media Queries --***********/
@media (max-width: 1024px) {

  .tsc-logo {
    background-size: contain;
    height: 93%;
    margin: 8px 0 0 0;
  }

  .bg-wrapper {
    margin-left: 40px;
  }

  .img-banner {
    /* display: block; */
    /* width: 55%; */
    margin-top: 10px;
  }

  .search-wrapper {
    position: absolute;
    top: 82px;
    left: 0;
    width: 100%;
    height: 60px;
    border-right: 0;
    border-left: 0;
    background: none;
  }

  .navbar-toggleable-md .navbar-toggler {
    display: block;
    top: 6px;
    height: 50px;
    width: 50px;
  }

  .navbar-toggleable-md .navbar-nav .nav-link {
    padding-right: .5em;
    padding-left: 0;
  }

  #navbars1.collapse.navbar-collapse {
    display: block;
  }

  /*.mt-42 {
    margin-top: 60px;
  }*/

  .form-inline {
    padding: 0;
    margin: 0 !important;
  }

  .form-inline .input-group {
    width: 100% !important;
  }

  .navbar-toggler.navbar-toggler-md {
    z-index: 555;
    top: 6px;
    left: -3px;
    height: 50px;
    width: 58px;
    box-sizing: border-box;
    position: absolute;
    display: block;
    outline: none;
  }

  .navbar-inverse .navbar-toggler {
    border: none;
  }

  .dropup .dropdown-menu,
  .container-nav.bg-inverse {
    display: none !important;
  }

  .navbar-nav.navbar-right .nav-item {
    /* margin-top: 8px; */
  }
}

@media (min-width: 1024px) and (max-width: 1366px) {
  /*  .nav-link h4 {
      font-size: 18px;
    }*/
  /*  .second-nav {
      font-size: 14px;
    }*/
}

@media (min-width: 767px) and (max-width: 1024px) {
  #desktop_content {
    display: none;
  }

  .img-banner {
    width: 45%;
    margin-top: 7px;
  }

  .navbar-toggleable-md .navbar-nav .nav-link {
    padding: .22rem .25rem !important;
  }

  .navbar-nav.navbar-right {
    margin-left: 33px !important;
  }

  /*.mt-42 {
    margin-top: 55px;
  }*/
  #storeAvailabilityModal .store-availability {
      /*padding-bottom: 100px !important;*/
}
}

@media (min-width: 1366px) {
  #desktop_content {
    display: block;
  }
}

@media (min-width: 600px) and (max-width: 960px) and (orientation: landscape),
(min-width: 600px) and (max-width: 960px) and (orientation: portrait) {
  .bg-wrapper {
    width: 29%;
    margin-top: -1px;
    margin-left: 36px;
  }

  tsc-logo {
    width: 76%;
  }
}

@media (min-width: 1366px) {
  #search_txtBox {
    border-bottom: none;
  }
}

@media (max-width: 767px) {
  #desktop_content {
    display: none;
  }

  .bg-image {
    z-index: 500;
    position: fixed;
    right: 0;
    left: 0;
    border-radius: 0;
    top: 0;
  }

  .img-banner {
    /* display: none */
  }

  .inner-image {
    padding-top: 26%;
    width: 96%;
  }

  .fa.fa-shopping-cart.text-large.text-grey {
    position: relative;
  }

  .bg-image {
    height: 65px;
  }

  .h_cart_count {
    top: 3px;
  }

  .fa.fa-bars.text-2 {
    font-size: 2rem;
  }

  .fa.text-large.text-grey {
    font-size: 28px;
  }

  .bg-grey-img.mt-120 {
    margin-top: 60px;
  }

  .container-lg {
    width: 100%;
  }

  .padding-l10 {
    padding-left: 10px;
  }

  .search-wrapper {
    top: 65px;
  }

    .modal-body.generic {
        padding-bottom:90px;
}
}

@media (min-width: 375px) and (max-width: 812px) and (orientation: landscape) {

  #navbar-left-wrapper {
    max-height: 170px;
  }

}

@supports (-webkit-overflow-scrolling: touch) and (orientation: landscape) {

  /* CSS specific to iOS devices */
  #navbar-left-wrapper {
    max-height: 203px;
  }

}

@media (min-width: 375px) and (max-width: 667px) and (orientation: portrait) {
  .navbar-toggler.navbar-toggler-md {
    top: 4px
  }

  .navbar-toggleable-sm .navbar-nav .nav-link {
    padding: .25rem .25rem;
  }

  .fa.text-large.text-grey {
    font-size: 30px;
  }

  .bg-wrapper {
    width: 30%;
    margin-bottom: 4px;
    margin-left: 40px;
  }

  .search-wrapper {
    top: 65px;
  }

  .inner-image {
    padding-top: 29% !important;
  }

}

@media (min-width: 320px) and (max-width: 568px) and (orientation: portrait) {
  .modal-title {
    font-size: 18px;
  }
}

@media (min-width: 320px) and (max-width: 731px) and (orientation: portrait),
(min-width: 320px) and (max-width: 731px) and (orientation: landscape) {
  .bg-wrapper {
    width: 92%;
    margin-bottom: 11px;
    margin-left: 32px;
    height: 80%;
  }

  .tsc-logo {
    width: 91% !important;
  }

  .navbar-inverse .search-wrapper {
    top: 65px !important;
  }

  .inner-image {
    padding-top: 24%;
  }

  .text-large.text-grey {
    font-size: 25px;
  }

  .navbar-toggler span,
  .nav-link span.text-1 {
    font-size: .9rem;
  }

  .navbar-toggler.navbar-toggler-md .text-2 {
    font-size: 1.5em !important;
  }

  .navbar-toggleable-sm .navbar-nav .nav-link {
    padding: .25rem .2rem;
  }

  .navbar-nav.flex-row {
    padding-top: 0;
  }

  /*.mt-42 {
    margin-top: 122px;
  }*/

  .container-lg .nopad {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

@media (min-width: 320px) and (max-width: 568px) and (orientation: landscape) {
  .inner-image {
    padding-top: 21%;
  }
}

@media (max-width: 767px) and (min-width: 320px) {
  .navbar-left {
    display: block;
  }
}

.tsc-store {
  position: relative;
  top: 1px;
  max-width: 100%;
  height: 36px;
  min-width: 25px;
  background: url(../images/2016-tsc-sprite.png) 40.3% 79.3%;
}

.tsc-help {
  max-width: 100%;
  height: 35px;
  background: url(../images/2016-tsc-sprite.png?$400SPRITEPNG$) 44.3% 49.75%;
}

.tsc-cart {
  position: relative;
  top: 1px;
  max-width: 100%;
  height: 36px;
  min-width: 25px;
  background: url(../images/2016-tsc-sprite.png) 40% 75%;
}

.tsc-order {
  margin-top: 7px;
}

.nav-link.cart {
  position: relative;
}

.h_cart_count {
  position: absolute;
  top: 5px;
  right: 1px;
  width: 22px;
  height: 19px;
  padding: 2px;
  border-radius: 50px;
  background-color: #d10000;
  vertical-align: middle;
  text-align: center;
  font: normal 12px/16px  'Roboto', sans-serif;
  color: #fff;
}

.shop-menu-inner-left,
.explore-menu-inner-left {
  z-index: 5000;
  background-color: #fff;
  padding: 1% 0 1% 15px !important;
  font-family: "Oswald-Bold", sans-serif;
  line-height: 1.3em;
  margin-left: 0px;
  font-weight: normal;
  font-size: 1.2em;
}

.shop-menu-inner-left a {
  margin: 3px 0px 3px 0px;
  width: 100%;
  cursor: pointer;
}

.explore-menu-inner-left {
  margin-left: 210px;
}

.shop-menu-inner-left a,
.shop-menu-inner-right h4 a,
.explore-menu-inner-left h4 a,
.explore-menu-inner-left a,
#desktop_content h2,
#shop-menu-expand .left_espot h2,
#shop_second_espot h2 {
  margin: 0 !important;
  width: 100%;
  display: block;
  cursor: pointer;
  color: #212529;
  line-height: 1.6em;
  margin: .3em 0;
  font-weight: normal;
  font-size: 18px;
  font-family: "Oswald-Regular", sans-serif;
  /*font-weight: 700;*/
}

.shop-menu-inner-left a:hover,
.shop-menu-inner-right h4 a:hover,
.explore-menu-inner-left h4 a:hover,
.explore-menu-inner-left a:hover {
  text-decoration: none;
  color: #212529;
}

#desktop_content ul,
#shop_second_espot ul {
  margin: 15px 0 0 0 !important;
  list-style: none;
}

#desktop_content {
  margin: 1em 0 0 0 !important;
  width: 90%;
}

.explore-menu-inner-right h4 {
  font-size: 1.4rem;
  font-weight: normal;
  font-family: "Oswald-Bold", sans-serif;
  line-height: 1.3rem;
}

#shop-menu-controller {
  margin-right: 25px;
  cursor: pointer;
}

#explore-menu-controller {
  cursor: pointer;
}

#new-explore-menu-controller {
  cursor: pointer;
}
#explore-menu-outer {}

.shop-nm-link,
.explore-nm-link {
  width: 100%;
}

.menu-hover-triangle-left {
  width: 0;
  height: 15px;
  border-top: 15px solid transparent;
  border-right: 15px solid #F3F3F3;
  border-bottom: 15px solid transparent;
  float: right;
  margin: 0;
  display: none;
}

.shop-menu-inner-right,
.explore-menu-inner-right {
  z-index: 5000;
  background-color: #F3F3F3 ;
  display: none;
}

.explore-menu-right-content {
  padding-right: 50px;
  box-sizing: content-box;
}

.shop-menu-inner-left,
.explore-menu-inner-left {
  display: none;
}

.responsive-menu-outer {
  z-index: 6000;
  width: 320px;
  background-color: #e5e2d9;
  padding-left: 2%;
  display: none;
}

#my-store-header {
  font-weight: normal;
  font-size: 24px;
  font-family: "Oswald-Bold", sans-serif;
  color: #231f20;
  margin-bottom: 1.5rem;
}

.green_txt {
  font-size: 19px;
  font-family: "Oswald-Bold", sans-serif;
  font-weight: 400;
  color: #638f38;
  float: right;
  cursor: pointer;
  /* margin: 10px; */
}

.storeName_content {
  font-size: 12px;
  font-weight: normal;
  color: #060603;
}

#navbar-left-wrapper {
  position: absolute;
  top: 121px;
  height: 457px;
  overflow: scroll;
  overflow-x: hidden;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.left_espot ul,
.left_espot div {
  list-style: none;
  /* margin: 0; */
}

#shop-menu-expand .left_espot ul {
  padding-left: 0
}

/*
#navbar-left-wrapper .header_a h2:after {
  font-family: icomoon;
  content: "\e906";
  font-size: 1.3em;
  float: right;
}
*/

#ExploreOutHereOverlay .header_a h2:after {
  content: "" !important;
}

#mobileKnowHow #ExploreOutHereOverlay {
  background-color: #e5e2d9 !important;
  height: auto !important;
}

#mobileKnowHow div#ExploreOutHereOverlay {
  background-color: #e5e2d9 !important;
  float: left;
  width: 100%;
  color: #231f20;
  height: 100%;
  font-family: "Oswald-Bold";
  font-size: 24px;
  font-weight: normal;
  margin-left: -24px !important;
  padding-left: 30px;
  width: 330px;
}

@media (min-width: 768px) {
  div#ExploreOutHereOverlay h2 {
    font-size: 24px;
  }
}

#mobileKnowHow div#ExploreOutHereOverlay h2 {
  font-size: 24px;
}

#mobileKnowHow #LifeOutHerelist {
  background: #e5e2d9;
  float: left;
  width: 100%;
  color: #231f20;
  height: 100%;
  font-family: "Oswald-Bold";
  font-weight: normal;
  max-width: 360px;
  min-height: 565px;
}

#mobileKnowHow #ELOH-nav {
  padding: 0 20px 0 0px;
  margin-left: -35px !important;
  width: 331px;
}

#mobileKnowHow #ELOH-nav div {
  margin: 0;
  width: 320px;
  background-color: #e5e2d9;
  opacity: 1.0;
  /* padding: 2%; */
  padding-left: 8px;
  position: relative;
  font-size: 1.4em;
  font-weight: bold;
}

.expand_menu {
  display: none
}

.navbar-left #mobileKnowHow ul li .ul-2nd-level li {
  margin-left: -35px !important;
  padding-left: 15px !important;
}

.modal-footer {
  border-top: none;
}

.modal-header {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

#searchDropdown {
  position: fixed;
  top: 100px;
  right: 0;
  left: 0;
  background: #fff;
  z-index: 950;
  bottom: 0;
  overflow-y: scroll;
  display: none;
  box-sizing: content-box;
  padding-right: 17px;
  width: 100%;
}

#AutoSuggestDiv{
    background: #fff;
}

#searchDropdown #AutoSuggestDiv a {
    color: #000;
    text-transform: capitalize;
}

#searchDropdown .autoSuggestDivNestedList #suggestionItem_0{
     text-decoration: underline;
     border-top: 1px dotted #ccc;
     margin-top: 10px;
}

#AutoSuggestDiv > ul,
.autoSuggestDivNestedList {
  padding: 0;
  z-index: 1000;
  margin-bottom: 0;
}

#AutoSuggestDiv > ul > li {
  margin: 0;
  padding: 0;
  list-style: none;
  z-index: 100;
}

#AutoSuggestDiv .list_section li {
  border-bottom: 1px dotted #cccccc;
  list-style: none;
  z-index: 100;
}

#AutoSuggestDiv .list_section li > a,
#AutoSuggestDiv #autoSuggestAdvancedSearch > a {
  display: block;
  height: 100%;
  padding: 15px;
  z-index: 100;
}

.row.three-up img {
  max-width: 100%;
  height: auto;
}

.sub_heading1 {
  margin: 15px 0 15px 0;
  white-space: nowrap;
  font: 24px "Alfa Slab One", serif;
  color: #393939;
}

.sub_heading2 {
  white-space: nowrap;
  font-weight: 700;
  font-size: 18px;
  font-family: 'Roboto', sans-serif;
  color: #393939;
}

.hm_col_fnt {
  text-align: justify;
  font: 14px  'Roboto', sans-serif;
}

/* Clear button styles
--------------------------------------------- */
::-ms-clear {
  display: none;
}

.form-control-clear {
  z-index: 10;
  pointer-events: auto;
  cursor: pointer;
  width: 22px;
  height: 22px;
  margin: 16px 28px 0 0;
  color: #7f8c8d;
  font-size: 26px;
  line-height: 26px;
  position: relative;
}

.form-control-clear:before {
  content: '';
  height: 22px;
  border-left: 2px solid #ccc;
  position: absolute;
  transform: rotate(-45deg);
}

.form-control-clear:after {
  content: '';
  height: 22px;
  border-left: 2px solid #ccc;
  position: absolute;
  transform: rotate(45deg);
}

.search-text:not(:valid) ~ .search-clear {
  display: none;
}

.search-text::-ms-clear {
  display: none;
}

#search_txtBox input:focus {
  box-shadow: none;
  border-right: none
}


/*Store Availability Popup*/
/*********************/
/* BOPIS Overlay:    */
/*********************/
.checkAvailDialog .green_tick_mob {
  max-width: 41%;
}

.checkAvailDialog .dress-title .mapp_price {
  display: block;
  margin-left: 0;
}

.checkAvailDialog .dress-title .mapp_price .link_to_checkout {
  width: 100%;
  margin-top: 5px;
}

.checkAvailDialog .dress-title .mapp_price .link_to_checkout .price_inred_sc {
  display: block;
}

.checkAvailDialog .dress-title .mapp_price .question_for_priceview {
  width: 100%;
}

.checkAvailDialog .dress-title .mapp_price .ques {
  float: none;
}

.outer_container {
  box-sizing: border-box;
  font-size: 12px;
  z-index: 1000;
}

.text {
  width: 385px;
  max-width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: justify;
  font-size: 11px;
}

.sweat-shirt {
  display: inline-block;
  padding: 10px 10px 0 14px;
}

#checkavailability_img {
  max-width: 75px;
}

.dress-title {
  display: inline-block;
  width: 230px;
  margin-left: 16px;
  vertical-align: top;
  font: bold 16px "Oswald-Regular", sans-serif;
  color: #393939;
}

.postal {
  margin-bottom: 25px;
  background-color: #e4e2d9;
  padding-bottom: 25px;
}

/*
.text-1 {
  padding: 10px 14px;
  font-size: 16px !important;
}
*/
.zip-code {
  display: inline-block !important;
  width: 148px !important;
  height: 32px !important;
  margin: 0 5px 0 14px !important;
  padding-left: 10px !important;
  border: 1px solid #dcdcdc !important;
  background-color: #fff;
  box-sizing: border-box;
  font-size: 12px !important;
  color: #000 !important;
}

.find-button {
  display: inline-block;
  width: 100px;
  height: 47px;
  padding-top: 1px;
  border: 2px solid #638f38 !important;
  vertical-align: middle;
  text-align: center;
  font-size: 14px;
  color: #eee;
}

.str_zipcd_val {
  margin-top: 10px;
  text-align: left;
  color: #d20000;
}

/*
.bopis_qty #qty_minus,
.bopis_qty #qty_plus {
  display: inline-block;
  height: 30px;
  border: 1px solid #d7d7d7;
  width: 30%;
  max-width: 30px;
  margin: 2px 0 0 4px;
  border-radius: 50%;
  line-height: 28px;
}
*/
.square1,
.square3 {
  /*  width: 42px !important;*/
  margin-top: 7px !important;
  padding-top: 0 !important;
}

@media (max-width: 1044px) and (min-width: 768px) {

  .menu_overlay,
  #navbar-left-wrapper {
    top: 138px;
    width: 320px;
  }

}

@media (min-width: 768px) and (max-width: 1023px) {
  .str_zipcd_val {
    margin-top: 3px;
  }
}

.new_wl_btnWrap {
  text-align: right;
}

/*********************/
/* BOPIS Overlay:    */
/*********************/
.changeStoreDialog .text {
  width: 100%;
}

.changeStoreDialog .dress-title {
  width: calc(100% - 120px);
  padding-bottom: 10px;
}

.changeStoreDialog .dress-title .mapp_price {
  display: block;
  margin-left: 0;
}

.changeStoreDialog .dress-title .mapp_price .link_to_checkout {
  display: block;
  width: 100%;
  margin-top: 5px;
}

.changeStoreDialog .dress-title .mapp_price .link_to_checkout .price_inred_sc {
  display: block;
}

.changeStoreDialog .dress-title .mapp_price .question_for_priceview {
  width: 100%;
}

.changeStoreDialog .dress-title .mapp_price .ques {
  float: none;
}

.changeStoreDialog .dress-title .ques_image_sc {
  width: 16px;
  height: 16px;
}

.changeStoreDialog .dress-title .bopis_product-price,
.bopis_product-qty {
  font-size: 14px;
}

.changeStoreDialog .city_sl_sr {
  width: 100%;
  margin-top: 5px;
}

.changeStoreDialog .address_sl_sr {
  width: 100%;
}

.changeStoreDialog .telephone_sl_sr {
  margin-top: 0;
  padding-bottom: 0;
}

#container_bopis-overlay .text {
  width: 100%;
  line-height: 1.6em;
}

#container_bopis-overlay .text-1 {
  padding: 16px;
  font-size: 16px;
}

#container_bopis-overlay .dress-title {
  width: calc(100% - 120px);
  margin-top: 12px;
  font-weight: normal;
}

#container_bopis-overlay .dress-title span {
  display: block;
}

#container_bopis-overlay .postal,
.bopis-section {
  clear: both;
  margin-top: 5px;
}

#container_bopis-overlay .postal {
  width: 96%;
}

.bopis-section {
  width: 100%;
  border-top: 1px solid #dcdcdc;
}

.bopis-section .address_lines_section {
  width: 100%;
}

.bopis-section .address_lines_section .add_phone_link_sl,
.bopis-section .ca_1 {
  width: 92%;
  margin: auto 4%;
}

.bopis-section .ca_1 .city_sl_sr {
  width: 100%;
  margin: 0;
}

#container_bopis-overlay .zip-code {
  height: 32px;
}

#container_bopis-overlay button.green.find-button {
  width: 100px;
  height: auto;
  margin: 2px 0;
}

@media all and (max-width: 767px) {
  .text {
    padding-left: 0;
  }

  .text-1 {
    font-size: 1rem;
    line-height: 1em;
  }

  .zip-code {
    width: 105px !important;
  }

  .find-button {
    width: 65px !important;
  }

  .changeStoreDialog {
    z-index: 1000 !important;
  }

  #container_bopis-overlay button.green.find-button {
    width: 100px !important;
  }

  .tsc-logo {
    width: 100%;
    /* background: url(/feo-cdn/8/_/rorRwdUnM.png) no-repeat left center; */
  }

  #espot_RoadiesHeaderBannerMobile,
  #espot_BOPISHeaderBannerMobile,
  #espot_CurbsideBannerMobile,
  #espot_HeaderBannerMobile {
    text-align: center;
    background: #333;
    position: fixed;
    bottom: 0px;
    left: 0;
    width: 100%;
    z-index: 1000;
  }

    div#espot_HeaderBannerMobile img,
    div#espot_BOPISHeaderBannerMobile img,
    div#espot_RoadiesHeaderBannerMobile img,
    div#espot_CurbsideBannerMobile img {
    display: inline-block;
    height: 25px;
    position: relative;
    top: 0px !important;
  }
}

.bopis_qty {
  margin: 0 0 10px 13%;
}

.bopis_qty img {
  vertical-align: unset;
}

.bopis_qty #sq_bopis_qty {
  min-height: auto;
}

.checkAvailDialog .bopis_qty .qty_plus_minus2 {
  margin-bottom: -3px;
}

.outOfstockDiv,
.inStockContainer {
  display: inline-block;
}

.inStockContainer {
  margin-right: 10px;
}

.inStockContainer:last-child {
  margin-right: 0;
}

.outOfstockDiv .instock-text {
  color: inherit;
}

.inStockSection {
  float: left;
  width: 65%;
  text-align: left;
  white-space: nowrap;
}

.green-tick {
  display: inline-block;
  margin: 0;
  color: #638e38;
}

.instock-text {
  display: inline-block;
  width: auto;
  padding-top: 6px;
  vertical-align: top;
  font-size: 12px;
  color: #638e38;
}

.store-distance {
  float: right;
  width: 35%;
  padding-top: 6px;
  text-align: right;
}

.qty_error {
  clear: both;
  width: 98%;
  line-height: 16px;
  padding-top: 10px;
  font-weight: bold;
  color: #c02239;
}

.address_lines_section {
  display: inline-block;
  width: 65%;
  line-height: 16px;
  margin-top: 10px;
  font-size: 12px;
  color: #000;
}

@media ALL and (max-width: 767px) {

  /*
  .bopis_qty {
    margin-left: 22%;
  }
  */
  .bopis_qty #qty_plus {
    margin-right: 0;
  }

  .address_lines_section {
    width: 60%;
    margin-top: 0;
    margin-left: 113px;
  }

  #container_bopis-overlay .address_lines_section {
    width: 100%;
    margin-top: 10px;
    margin-left: 0;
  }

  .green_tick_mob {
    width: 100% !important;
    margin-top: 0 !important;
    margin-left: -10px !important;
  }
}

.my-tsc-store {
  color: #ffa500;
}

.phone-line {
  display: inline-block;
  font-weight: bold;
  font-size: 11px;
}

.buttons-section button {
  margin: 10px 0 30px 0;
  width: auto;
  font-size: 13px;
}

.btn_store-details {
  float: left !important;
}

.btn_pickup-here {
  float: right !important;
}

.bopis_product-title,
.bopis_product-options,
.bopis_product-price,
.bopis_product-qty {
  margin-bottom: 5px;
}

.bopis_product-title,
.bopis_product-price {
  font-weight: bold;
}

.bopis_product-sku {
  color: #888;
}

.bopis_product-price,
.bopis_product-qty {
  font-size: 22px;
}

/* /BOPIS Overlay */
#checkAvail_jcontent .inStockSection {
  white-space: normal;
}

#checkAvail_jcontent .outOfstockDiv .green-tick_cross,
#checkAvail_jcontent .inStockContainer .green_tick_mob {
  max-width: 16px;
  margin: 0 !important;
}

#checkAvail_jcontent .outOfstockDiv .green-tick_cross {
  padding-top: 4px;
}

@media ALL and (max-width: 767px) {
  #checkAvail_jcontent .address_lines_section {
    width: 100%;
    margin-top: 10px;
    margin-left: 0;
  }
}

#checkAvail_jcontent button.green.find-button {
  float: none;
  width: 100px;
  height: 34px !important;
  margin: 0;
  line-height: 30px;
}

@media ALL and (max-width: 767px) {
  #checkAvail_jcontent button.green.find-button {
    width: 100px !important;
  }
}

#checkAvail_jcontent .text {
  width: 100%;
  line-height: 1.6em;
  color: #000;
}

#checkAvail_jcontent .dress-title {
  margin-top: 12px;
  font-weight: normal;
  font-size: 16px;
}

#checkAvail_jcontent .dress-title span {
  display: block;
}

#checkAvail_jcontent .dress-title span.actual_price,
#checkAvail_jcontent .dress-title span.save_text {
  font-size: 11px !important;
}

#checkAvail_jcontent .dress-title span.actual_price {
  float: left;
  padding-right: 5px;
}

#checkAvail_jcontent .dress-title span.save_text:after {
  content: "";
  display: block;
  clear: left;
}

@media (max-width: 767px) {
  #checkAvail_jcontent .dress-title span.save_text {
    margin-top: 0;
  }
}

#checkAvail_jcontent .postal {
  clear: both;
  width: 100%;
  margin-top: 5px;
}

#checkAvail_jcontent .text-1 {
  padding: 16px;
  font-size: 16px;
}

#checkAvail_jcontent .zip-code {
  height: 40px !important;
  line-height:normal;
}

.noimg img {
  max-width: 0;
}

.gray_color {
  color: #808080 !important;
}

.error_class {
  display: none;
  margin-top: -12px;
  margin-left: 123px;
  color: #f00;
}

#displayButtonDiv {
  height: 120px;
}

.square1,
.square2,
.square3 {
  float: left;
  line-height: 33px;
  margin-top: 5px;
  border: 1px solid #d7d7d7 !important;
  text-align: center;
}

.square1,
.square3 {
  width: 35px;
  border-radius: 50%;
  cursor: pointer;
}

/*
.square1 {
  height: 37px;
}
*/
#sq_qty,
.square2,
.sq_qty {
  width: 4em !important;
    height: 32px !important;
  margin-left: 9px;
  padding-top: 5px;
  border-radius: 0 !important;
  box-sizing: content-box;
  -webkit-appearance: none !important;
  -webkit-border-radius: 0 !important;
}

#sq_qty.error,
.square2.error,
.sq_qty.error {
  border-color: #ca232b !important;
  color: #ca232b;
}

.square3 {
  height: 33px;
  margin-left: 9px;
  padding-top: 5px;
}

.qty_plus_minus1,
.qty_plus_minus2 {
  width: 15px;
  background-color: rgba(0, 0, 0, 0);
  background-image: url("../../../images/20160401-tsc-pdp-sprite.png?$35PDPSPRITE$");
  background-repeat: no-repeat;
  background-attachment: scroll;
}

.qty_plus_minus1 {
  height: 2px;
  margin-bottom: 3px;
  background-position: 105% 98.7%;
}

.qty_plus_minus2 {
  height: 15px;
  margin-bottom: -3px;
  background-position: 100% 97.5%;
}

.qty-error {
  position: relative;
  top: 15px;
  width: calc(100% - 80px);
  line-height: 1.6em;
  margin: 20px 0 10px 0;
  padding: 15px 25px;
  border: 1px solid #ffc7c7;
  background: #fff0f0;
  font-size: 16px;
  color: #d20000;
}

.clearBoth,
.clear-fix,
.clear_float,
.clearFloat {
  clear: both;
}

.modal-header button.close:before {
  content: '';
  height: 22px;
  border-left: 4px solid #000;
  position: absolute;
  transform: rotate(-45deg);
  top: 2px;
  left: 10px;
}

.modal-header button.close:after {
  content: '';
  height: 22px;
  border-left: 4px solid #000;
  position: absolute;
  transform: rotate(45deg);
  top: 2px;
  left: 10px;
}

.modal-header button.close {
  margin: 0;
  padding: 0;
  width: 24px;
  height: 24px;
  right: 4px;
  top: 7px;
}

#availabilityModal .modal-body {
  padding: 20px;
  max-height: 800px;
  overflow-y: scroll;
}

/*Store Availability Popup*/
/*********************/
/* BOPIS Overlay:    */
/*********************/
.checkAvailDialog .green_tick_mob {
  max-width: 41%;
}

.checkAvailDialog .dress-title .mapp_price {
  display: block;
  margin-left: 0;
}

.checkAvailDialog .dress-title .mapp_price .link_to_checkout {
  width: 100%;
  margin-top: 5px;
}

.checkAvailDialog .dress-title .mapp_price .link_to_checkout .price_inred_sc {
  display: block;
}

.checkAvailDialog .dress-title .mapp_price .question_for_priceview {
  width: 100%;
}

.checkAvailDialog .dress-title .mapp_price .ques {
  float: none;
}

.outer_container {
  box-sizing: border-box;
  font-size: 12px;
  z-index: 1000;
}

.text {
  width: 385px;
  max-width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: justify;
  font-size: 11px;
}

.sweat-shirt {
  display: inline-block;
  padding: 10px 10px 0 14px;
}

#checkavailability_img {
  max-width: 75px;
}

.dress-title {
  display: inline-block;
  width: 230px;
  margin-left: 16px;
  vertical-align: top;
  font: bold 16px "Oswald-Regular", sans-serif;
  color: #393939;
}

.postal {
  margin-bottom: 25px;
  background-color: #e4e2d9;
  padding-bottom: 25px;
}

/**
This style is causing serious header misalignment. UI team needs to review this.
.text-1 {
  padding: 10px 14px;
  font-size: 16px !important;
}
**/
.zip-code {
  display: inline-block !important;
  width: 148px !important;
  height: 32px !important;
  margin: 0 5px 0 14px !important;
  padding-left: 10px !important;
  border: 1px solid #dcdcdc !important;
  background-color: #fff;
  box-sizing: border-box;
  font-size: 12px !important;
  color: #000 !important;
}

.find-button {
  display: inline-block;
  width: 100px;
  height: 47px;
  padding-top: 1px;
  border: 2px solid #638f38 !important;
  vertical-align: middle;
  text-align: center;
  font-size: 14px;
  color: #eee;
}

.str_zipcd_val {
  margin-top: 10px;
  text-align: left;
  color: #d20000;
}

/*
.bopis_qty #qty_minus,
.bopis_qty #qty_plus {
  display: inline-block;
  height: 30px;
  border: 1px solid #d7d7d7;
  width: 30%;
  max-width: 30px;
  margin: 2px 0 0 4px;
  border-radius: 50%;
  line-height: 28px;
}
*/
.square1,
.square3 {
  /*  width: 42px !important;*/
  margin-top: 7px !important;
  padding-top: 0 !important;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .str_zipcd_val {
    margin-top: 3px;
  }
}

.new_wl_btnWrap {
  text-align: right;
}

/*********************/
/* BOPIS Overlay:    */
/*********************/
.changeStoreDialog .text {
  width: 100%;
}

.changeStoreDialog .dress-title {
  width: calc(100% - 120px);
  padding-bottom: 10px;
}

.changeStoreDialog .dress-title .mapp_price {
  display: block;
  margin-left: 0;
}

.changeStoreDialog .dress-title .mapp_price .link_to_checkout {
  display: block;
  width: 100%;
  margin-top: 5px;
}

.changeStoreDialog .dress-title .mapp_price .link_to_checkout .price_inred_sc {
  display: block;
}

.changeStoreDialog .dress-title .mapp_price .question_for_priceview {
  width: 100%;
}

.changeStoreDialog .dress-title .mapp_price .ques {
  float: none;
}

.changeStoreDialog .dress-title .ques_image_sc {
  width: 16px;
  height: 16px;
}

.changeStoreDialog .dress-title .bopis_product-price,
.bopis_product-qty {
  font-size: 14px;
}

.changeStoreDialog .city_sl_sr {
  width: 100%;
  margin-top: 5px;
}

.changeStoreDialog .address_sl_sr {
  width: 100%;
}

.changeStoreDialog .telephone_sl_sr {
  margin-top: 0;
  padding-bottom: 0;
}

#container_bopis-overlay .text {
  width: 100%;
  line-height: 1.6em;
}

#container_bopis-overlay .text-1 {
  padding: 16px;
  font-size: 16px;
}

#container_bopis-overlay .dress-title {
  width: calc(100% - 120px);
  margin-top: 12px;
  font-weight: normal;
}

#container_bopis-overlay .dress-title span {
  display: block;
}

#container_bopis-overlay .postal,
.bopis-section {
  clear: both;
  margin-top: 5px;
}

#container_bopis-overlay .postal {
  width: 96%;
}

.bopis-section {
  width: 100%;
  border-top: 1px solid #dcdcdc;
}

.bopis-section .address_lines_section {
  width: 100%;
}

.bopis-section .address_lines_section .add_phone_link_sl,
.bopis-section .ca_1 {
  width: 92%;
  margin: auto 4%;
}

.bopis-section .ca_1 .city_sl_sr {
  width: 100%;
  margin: 0;
}

#container_bopis-overlay .zip-code {
  height: 32px;
}

#container_bopis-overlay button.green.find-button {
  width: 100px;
  height: auto;
  margin: 2px 0;
}

@media (max-width: 767px) {
  .text {
    padding-left: 0;
  }

  .zip-code {
    width: 105px !important;
  }

  .find-button {
    width: 65px !important;
  }

  .changeStoreDialog {
    z-index: 1000 !important;
  }

  #container_bopis-overlay button.green.find-button {
    width: 100px !important;
  }

    .left_espot .footMenu .footer_li,
    .left_espot .footer_menu_aTag_font a {
    color: #000 !important;
  }

  .img-banner {
    position: absolute;
    bottom: 0;
    width: 100%
  }
}

.bopis_qty {
  margin: 0 0 10px 13%;
}

.bopis_qty img {
  vertical-align: middle;
}

.bopis_qty #sq_bopis_qty {
  min-height: auto;
}

.checkAvailDialog .bopis_qty .qty_plus_minus2 {
  margin-bottom: -3px;
}

.outOfstockDiv,
.inStockContainer {
  display: inline-block;
}

.inStockContainer {
  margin-right: 10px;
}

.inStockContainer:last-child {
  margin-right: 0;
}

.outOfstockDiv .instock-text {
  color: inherit;
}

.inStockSection {
  float: left;
  width: 65%;
  text-align: left;
  white-space: nowrap;
}

.green-tick {
  display: inline-block;
  margin: 0;
  color: #638e38;
}

.instock-text {
  display: inline-block;
  width: auto;
  padding-top: 6px;
  vertical-align: top;
  font-size: 12px;
  color: #638e38;
}

.store-distance {
  float: right;
  width: 35%;
  padding-top: 6px;
  text-align: right;
}

.qty_error {
  clear: both;
  width: 98%;
  line-height: 16px;
  padding-top: 10px;
  font-weight: bold;
  color: #c02239;
}

.address_lines_section {
  display: inline-block;
  width: 65%;
  line-height: 16px;
  margin-top: 10px;
  font-size: 12px;
  color: #000;
}

.bopis-section a {
  color: #000;
  text-decoration: none;
}

@media ALL and (max-width: 767px) {

  .bopis_qty #qty_plus {
    margin-right: 0;
  }

  .address_lines_section {
    width: 60%;
    margin-top: 0;
    margin-left: 113px;
  }

  #container_bopis-overlay .address_lines_section {
    width: 100%;
    margin-top: 10px;
    margin-left: 0;
  }

  .green_tick_mob {
    width: 100% !important;
    margin-top: 0 !important;
    margin-left: -10px !important;
  }
}

.my-tsc-store {
  color: #ffa500;
}

.phone-line {
  display: inline-block;
  font-weight: bold;
  font-size: 11px;
}

.buttons-section button {
  margin: 10px 0 30px 0;
  width: auto;
  font-size: 13px;
}

.btn_store-details {
  float: left !important;
}

.btn_pickup-here {
  float: right !important;
}

.bopis_product-title,
.bopis_product-options,
.bopis_product-price,
.bopis_product-qty {
  margin-bottom: 5px;
}

.bopis_product-title,
.bopis_product-price {
  font-weight: bold;
}

.bopis_product-sku {
  color: #888;
}

.bopis_product-price,
.bopis_product-qty {
  font-size: 22px;
}

/* /BOPIS Overlay */
#checkAvail_jcontent .inStockSection {
  white-space: normal;
}

#checkAvail_jcontent .outOfstockDiv .green-tick_cross,
#checkAvail_jcontent .inStockContainer .green_tick_mob {
  max-width: 16px;
  margin: 0 !important;
}

#checkAvail_jcontent .outOfstockDiv .green-tick_cross {
  padding-top: 4px;
}

@media ALL and (max-width: 767px) {
  #checkAvail_jcontent .address_lines_section {
    width: 100%;
    margin-top: 10px;
    margin-left: 0;
  }
}

#checkAvail_jcontent button.green.find-button {
  float: none;
  width: 100px;
  height: 34px !important;
  margin: 0;
  line-height: 30px;
}

@media ALL and (max-width: 767px) {
  #checkAvail_jcontent button.green.find-button {
    width: 100px !important;
  }
}

#checkAvail_jcontent .text {
  width: 100%;
  line-height: 1.6em;
  color: #000;
}

#checkAvail_jcontent .dress-title {
  margin-top: 12px;
  font-weight: normal;
  font-size: 16px;
}

#checkAvail_jcontent .dress-title span {
  display: block;
}

#checkAvail_jcontent .dress-title span.actual_price,
#checkAvail_jcontent .dress-title span.save_text {
  font-size: 11px !important;
}

#checkAvail_jcontent .dress-title span.actual_price {
  float: left;
  padding-right: 5px;
}

#checkAvail_jcontent .dress-title span.save_text:after {
  content: "";
  display: block;
  clear: left;
}

@media (max-width: 767px) {
  #checkAvail_jcontent .dress-title span.save_text {
    margin-top: 0;
  }
}

#checkAvail_jcontent .postal {
  clear: both;
  width: 100%;
  margin-top: 5px;
}

#checkAvail_jcontent .text-1 {
  padding: 16px;
  font-size: 16px;
}

#checkAvail_jcontent .zip-code {
  height: 40px !important;
}

.noimg img {
  max-width: 0;
}

.gray_color {
  color: #808080 !important;
}

.error_class {
  display: none;
  margin-top: -12px;
  margin-left: 123px;
  color: #f00;
}

#displayButtonDiv {
  height: 120px;
}

.square1,
.square2,
.square3 {
  float: left;
  line-height: 33px;
  margin-top: 5px;
  border: 1px solid #d7d7d7 !important;
  text-align: center;
}

.square1,
.square3 {
  width: 35px;
  border-radius: 50%;
  cursor: pointer;
}

#sq_qty,
.square2,
.sq_qty {
  width: 4em !important;
    height: 32px !important;
  margin-left: 9px;
  margin-right: 9px;
  padding-top: 5px;
  border-radius: 0 !important;
  box-sizing: content-box;
  -webkit-appearance: none !important;
  -webkit-border-radius: 0 !important;
}

#sq_qty.error,
.square2.error,
.sq_qty.error {
  border-color: #ca232b !important;
  color: #ca232b;
}

.square3 {
  height: 33px;
  margin-left: 9px;
  padding-top: 5px;
}

.qty_plus_minus1,
.qty_plus_minus2 {
  width: 15px;
  background-color: rgba(0, 0, 0, 0);
  background-image: url("../../../images/20160401-tsc-pdp-sprite.png?$35PDPSPRITE$");
  background-repeat: no-repeat;
  background-attachment: scroll;
}

.qty_plus_minus1 {
  height: 2px;
  margin-bottom: 3px;
  background-position: 105% 98.7%;
}

.qty_plus_minus2 {
  height: 15px;
  margin-bottom: -3px;
  background-position: 100% 97.5%;
}

.qty-error {
  position: relative;
  top: 15px;
  width: calc(100% - 80px);
  line-height: 1.6em;
  margin: 20px 0 10px 0;
  padding: 15px 25px;
  border: 1px solid #ffc7c7;
  background: #fff0f0;
  font-size: 16px;
  color: #d20000;
}

.clearBoth,
.clear-fix,
.clear_float,
.clearFloat {
  clear: both;
}

.modal-header button.close:before {
  content: '';
  height: 22px;
  border-left: 4px solid #000;
  position: absolute;
  transform: rotate(-45deg);
  top: 2px;
  left: 10px;
}

.modal-header button.close:after {
  content: '';
  height: 22px;
  border-left: 4px solid #000;
  position: absolute;
  transform: rotate(45deg);
  top: 2px;
  left: 10px;
}

.modal-header button.close span {
  display: none;
}

#availabilityModal button.close,
#quickInfoPopup button.close {
  margin: 0;
  padding: 0;
  width: 24px;
  height: 24px;
  right: 4px;
  top: 4px;
}

#availabilityModal .modal-lg,
#quickInfoPopup .modal-lg {}

#availabilityModal .modal-body,
#quickInfoPopup .modal-body {
  padding: 20px;
  overflow-y: auto;
}


/*Hacky Fix for +/- buttons*/
#qty_minus,
#qty_plus {
  margin: 0;
  padding: 0;
  height: 35px;
  width: 35px;
}

#qty_minus{
  line-height: 27px;
}

#qty_plus{
  line-height: 32px;
}

#qty_minus img,
#qty_plus img {
  display: none;
}

#qty_minus::after,
#qty_plus::after {
  font-size: 34px;
  color: red;
}

#qty_minus::after {
  content: "-";
  font-size: 44px;
}

#qty_plus::after {
  content: "+"
}

/** Styles for recomended product carousel : starts  **/
.carousel .sale-item,
.carousel .clearance-item {
  text-transform: uppercase;
  font-weight: bolder;
}

.carousel .sale-item:before,
.carousel .clearance-item:before {
  position: absolute;
  padding: 0.1rem .5rem;
}

.sale-item {
  color: #fff;
}

.clearance-item {
  color: #000;
}

.sale-item:before {
  content: "On Sale";
  background-color: #d20000;
}

.clearance-item:before {
  content: "Clearance";
  background-color: #ffcb34;
}

.carousel .product_label {
  font-weight: bolder;
}

.carousel .product_label a {
  color: #231f20;
    text-decoration: none !important;
}

.carousel .product_label {
  font-family: 'Roboto', sans-serif;
}

/** Styles for recomended product carousel : ends  **/
/** styles for footer **/
.connect_text {
  margin: 10px 30px 10px 29px;
  vertical-align: middle;
  text-align: center;
  font-size: 15px;
  line-height: 29px;
  font-family: 'Roboto', sans-serif;
  color: #231f20;
}

.email_store_connect_text div {
  line-height: 29px;
  /* margin-top: 22px; */
  text-align: center;
  font-weight: normal;
  font-size: 18px;
  font-family: 'Roboto', sans-serif;
  color: #69913d;
}

.social_networks {
  width: 100%;
  height: 100px;
  margin-top: 28px;
  text-align: center;
  line-height: 8;
}

a.img_link {
  margin:0 0 0 7px;
  border: 0;
  line-height: 6;
  text-decoration: none;
  color: #e4e2d9;
  width:15%;
  display:inline-block;
}

.second_container_outer {
  background: #353535 url(//media.tractorsupply.com/is/image/TractorSupplyCompany/20150508-tsc-footer-bg?$2880$);
}

.footMenu .footer_li {
  float: left;
  list-style: none;
  text-transform: uppercase;
  font-size: 24px;
  font-family: "Oswald-Regular", sans-serif;
  /* color: #fff !important; */
}

footMenu .footer_li:hover {
  color: #000 !important;
}

.footer_menu_aTag_font a {
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  display: block;
}

.footer_menu_aTag_font a:hover {
  color: #000 !important;
}

.about_container:hover,
.customer_container:hover,
.career_container:hover,
.credit_container:hover,
.know_container:hover {
  background-color: #e4e2d9;
  color: #000;
}

.about_container {
  width: 15%;
  height: 60px;
  margin-left: 0;
  border-right: 2px solid #000;
}

.customer_container {
  display: inline-block;
  width: 25%;
  height: 60px;
  border-right: 2px solid #000;
  vertical-align: middle;
  cursor: pointer;
}

.know_container {
  display: inline-block;
  width: 23%;
  height: 60px;
}

.career_container {
  display: inline-block;
  width: 15%;
  height: 60px;
  border-right: 2px solid #000;
  vertical-align: middle;
}

.credit_container {
  display: inline-block;
  width: 20%;
  height: 60px;
  border-right: 2px solid #000;
  vertical-align: middle;
}

.flyout_div {
  position: absolute;
  top: 0;
  bottom: 90px;
  left: 1%;
  width: 100%;
  /* height: 300px; */
  background-color: #F3F3F3;
  text-transform: none;
  z-index: 5000;
  right: auto;
}

@media (min-width: 1920px) {

    #cust_solns_div,
    #know_how_cent_div {
    left: 12.5% !important;
    /* max-width: 1440px; */
  }
}

@media (min-width: 1441px) {

    #about_us_div,
    #cust_solns_div,
    #know_how_cent_div {
    right: auto;
    left: 9%;
    max-width: 100%;
  }
}

.flyout_div div ul {
  padding: 0;
  list-style-type: none;
}

.flyout_div:hover {
  color: #000;
}

.cust_sol_flyout {
  width: 100%;
  height: auto;
  background-color: #e5e5e5;
  color: #000;
}

.flyout_div ul li {
  cursor: pointer;
}

#cust_solns_div .cust_sol_flyout .four_con_cust li div {
  font-size: 15px;
}

.footer_list {
  clear: left;
  margin-right: 20px;
  list-style: none outside none;
  text-decoration: none;
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
  color: #231f20 !important;
}

.footer_list a {
  color: #231f20 !important;
  font-size: 16px;
}

.list_item {
  margin-bottom: 15px;
  line-height: 20px;
}

.footer_list a:hover {
  color: #d20000 !important;
}

footer_menu_aTag_font:hover {
  text-decoration: none;
  font-size: 16pt;
  color: #000;
  cursor: pointer;
}

.text_footer,
.text_footer_ht {
  vertical-align: middle;
  text-align: center;
  font-weight: bold;
  line-height: 47px;
  font-family: 'Roboto', sans-serif;
  /* color: #fff !important; */
}

.know_container:hover,
.customer_container:hover,
.about_container:hover,
.about_container:active,
.career_container:hover,
.credit_container:hover {
  background-color: #e5e2d9;
  color: #000 !important;
}

.first_con_cust,
.second_con_cust,
.third_con_cust,
.four_con_cust,
.four_con {
  float: left;
  width: 17%;
  margin-top: 20px;
  margin-left: 30px;
  border-right: 1px solid #d7d7d7;
  text-align: left;
  line-height: 30px;
  font-size: 16px;
}

.four_con_cust,
.four_con {
  width: 29% !important;
}

.footMenu .footer_li:last-child {
  margin-bottom: 1px;
  border: none;
}

.footer_container {
  width: 100%;
  height: 300px;
}

.second_container {
  width: 100%;
  height: 60px;
}

.third_container {
  width: 100%;
  height: 30px;
  background-color: #000;
  font-size: 12px;
}

.third_cont_text {
  float: left;
  margin-left: 30px;
  vertical-align: middle;
  line-height: 27px;
  font-family: 'Roboto', sans-serif;
  color: #999;
}

#know_how_cent_div {
  /* left: 1.5%; */
  /* max-width: 1440px; */
}

#cust_solns_div {
  /* left: auto; */
  /* right: auto; */
  /* max-width:  1440px; */
  /* position: relative; */
}

.four_con_cust a {
  color: #000 !important;
}

.footer_menu_aTag_font:hover,
.footer_menu_aTag_font:active {
  text-decoration: none;
  font-size: 16pt;
  color: #000;
  cursor: pointer;
}
.footer,
#footerDisplay {
    left: 0;
  bottom: 0;
  clear: both;
    position: relative;
    height: auto;
}

@media ALL and (min-width: 1441px) {

  .footer_menu_aTag_font a:hover {
    color: #000;
    cursor: pointer;
  }

  .second_container_outer {
    width: 100%;
  }

  .second_container {
    max-width: 1440px;
    margin-right: auto;
    margin-left: auto;
  }

  .footer_container {
    max-width: 1440px;
    margin-right: auto;
    margin-left: auto;
  }

  .footer_container_outer {
    width: 100%;
    background-color: #e4e2d9;
  }

  .third_container_outer {
    width: 100%;
    background-color: #000;
  }

  .third_container {
    max-width: 1440px;
    margin-right: auto;
    margin-left: auto;
    border-right: 2px solid #000;
    border-left: 2px solid #000;
  }

}

@media (max-width: 1080px) {

  .first_con_cust,
  .second_con_cust,
  .third_con_cust,
  .four_con_cust,
  .four_con {
    width: 15%;
  }
}

@media (max-width: 767px) {
  .second_container_outer {
    background: #e4e2d9;
  }

  .footer_container {
    display: none;
  }

  .second_container {
    width: 100%;
    height: 60px;
  }

  .first_con_cust,
  .four_con {
    width: 19% !important;
    height: 330px;
    word-wrap: break-word;
  }

  .second_con_cust,
  .third_con_cust {
    height: 330px;
    word-wrap: break-word;
  }

  .flyout_div {
    height: 365px !important;
  }

  .second_container {
    display: inline-block;
    height: auto;
    margin-bottom: 5px;
  }

  .third_container {
    width: 100%;
    height: 57px;
    background-color: #000;
    font-size: 12px;
  }

    .about_container,
    .customer_container,
    .know_container,
    .career_container,
  .credit_container {
    width: 89.5%;
    height: 42px;
    margin-right: 5%;
    margin-left: 5%;
    border-right: none;
    border-bottom: thin solid #c0c0c0;
    background-color: #e4e2d9;
    text-align: left;
  }

  .know_container {
    border-bottom: none;
  }

    .footMenu .footer_li,
    .footer_menu_aTag_font a {
    text-transform: none;
    font-weight: 100;
    color: #4c4c4c !important;
    font-size: 20px;
  }

  .third_cont_text {
    margin-bottom: 30px;
    margin-left: 20px;
    line-height: 56px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {

  .footMenu .footer_li {
    font-size: 20px;
  }

  .flyout_div {
    display: none;
  }

  .footer_list {
    font-size: 15px;
  }

  .first_con_cust,
  .second_con_cust,
  .third_con_cust {
    height: 320px;
  }

  .second_con_cust {
    width: 19% !important;
  }

  .third_con_cust {
    width: 19% !important;
  }

  .four_con {
    width: 17%;
    height: 320px;
  }
}

/*Quick Info Popup internal styles*/
.lb_h1_prc {
  float: left;
  width: 83%;
  font: 15px "Alfa Slab One", serif;
  color: #333;
}

.lb_product_details {
  height: 120px;
}

.lb_prd_dtls {
  float: left;
  width: 60%;
  margin-top: 10px;
}

.lb_img {
  margin-top: 14px;
}

.lb_imgdiv {
  float: left;
  width: 40%;
  height: auto;
  margin: auto;
  text-align: center;
}

.lb_prd_dtls .question_for_priceview,
.lb_product_details .lb_imgdiv .lb_img {
  height: 100px;
}

.lb_details1 {
  margin-top: 7px;
  text-align: left;
  word-wrap: break-word;
  font: 18px "Oswald-Bold", sans-serif;
  color: #333;
}

.lb_details2 {
  display: block;
  width: 100%;
  height: auto;
  margin-top: 7px;
  margin-bottom: 10px;
  text-align: left;
}

.lb_txt1,
.lb_txt2,
.lb_txt3 {
  clear: both;
  display: block;
  font-family: 'Roboto', sans-serif;
}

.lb_txt1 {
  font-weight: bold;
  font-size: 14px;
}

.lb_txt2,
.lb_txt3 {
  font-weight: 400;
  font-size: 13px;
}

.lb_txt2 {
  text-decoration: line-through;
  color: rgb(153, 153, 153);
}

.lb_txt3 {
  color: #d20000;
}

.lb_label {
  display: block;
  margin: 6px 0;
  font: 16px  'Roboto', sans-serif;
  color: #232323;
}

.lb_selections_inner {
  clear: both;
  float: right;
  width: 60%;
  height: auto;
  text-align: left;
}

.lb_selections {
  height: 330px;
  font: 12px  'Roboto', sans-serif;
}

.lb_a {
  display: block;
  margin: 10px 0;
  text-decoration: none;
  font: 11px  'Roboto', sans-serif;
  color: #265781 !important;
}

.lb_addtocart_btn {
  width: 155px;
  height: auto;
  padding: 9px 9px 10px 9px;
  border: 1px solid #446621;
  background: rgba(0, 0, 0, 0) -moz-linear-gradient(90deg, #649430 0%, #69ba04 100%) repeat scroll 0 0;
  background-image: -webkit-linear-gradient(90deg, rgb(100, 148, 48) 0, rgb(105, 186, 4) 100%);
  background-image: -ms-linear-gradient(90deg, rgb(100, 148, 48) 0, rgb(105, 186, 4) 100%);
  font-weight: bold;
}

.lb_addtocart_btn:hover {
  border-color: #488406;
  background-image: -moz-linear-gradient(90deg, rgb(89, 162, 10) 0, rgb(119, 211, 6) 100%);
  background-image: -webkit-linear-gradient(90deg, rgb(89, 162, 10) 0, rgb(119, 211, 6) 100%);
  background-image: -ms-linear-gradient(90deg, rgb(89, 162, 10) 0, rgb(119, 211, 6) 100%);
}

.lb_cancel_btn {
  width: 155px;
  height: auto;
  padding: 10px 20px 9px 20px;
  border: 1px solid #cfd0d0;
  background-image: -moz-linear-gradient(90deg, rgb(218, 218, 219) 0, rgb(252, 250, 250) 100%);
  background-image: -webkit-linear-gradient(90deg, rgb(218, 218, 219) 0, rgb(252, 250, 250) 100%);
  background-image: -ms-linear-gradient(90deg, rgb(218, 218, 219) 0, rgb(252, 250, 250) 100%);
  color: #005ebd;
}

.lb_cancel_btn:hover {
  border-color: #779bc0;
  background-image: -moz-linear-gradient(90deg, rgb(224, 224, 226) 0, rgb(255, 255, 255) 100%);
  background-image: -webkit-linear-gradient(90deg, rgb(224, 224, 226) 0, rgb(255, 255, 255) 100%);
  background-image: -ms-linear-gradient(90deg, rgb(224, 224, 226) 0, rgb(255, 255, 255) 100%);
}

.lb_btndiv {
  float: left;
  clear: left;
  width: auto;
  height: auto;
  padding: 0 10px 7px 0;
}

.select_css {
  width: 50%;
  height: 35px;
  background-color: rgb(240, 240, 240);
  border: 1px solid #d7d7d7;
}

@media (max-width: 400px) {
  .select_css {
    width: 60%;
  }
}

.price_red {
  clear: both;
  display: block;
  line-height: 1.6em;
  color: #d20000;
}

#quickInfoPopup .dollar_price {
  font: normal 12px  'Roboto', sans-serif;
  color: #a59e9f;
}

#quickInfoPopup .dollar_price {
  width: 100%;
  height: 40px;
  margin-left: 0;
  text-align: left;
}

#quickInfoPopup .ques,
#quickInfoPopup .ques_mark {
  float: none;
  display: inline-block;
}

#quickInfoPopup .ques {
  width: auto;
  height: 20px;
  margin-top: 1px;
  font-size: 12px;
  color: #a59e9f;
}

#quickInfoPopup .ques_mark {
  width: 12px;
  height: 12px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

#quickInfoPopup .ques_mark {
  background-image: url("../../images/ques_symbol.png");
  background-repeat: no-repeat;
  cursor: pointer;
  background-size: 100% 100%;
  height: 12px;
  width: 12px;
  display: inline-block;
}

.swatchContainer {
  clear: left;
  width: 100%;
  padding: 8px 0 0 0;
}

.swatchContainer a {
  display: inline-block;
  padding: 4px;
  border: 1px solid #ccc;
}

.swatchContainer a img {
  height: 40px;
}

.swatch {
  display: inline-block;
  position: relative;
  margin: 0 0 3px 0;
}

.swatchContainer a:hover {
  border-color: #000;
}

.swatchContainer .selected {
  border: 1px solid #000;
}

.swatchContainer .unavailable a {
  border: 1px solid #ccc;
}

.swatchContainer .unavailable a img {
  opacity: .1;
}
.swatchContainer .after {
  display:none;
}
.swatchContainer .unavailable .after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display:block;
}


.navbar-left a.green_txt {
  color: #638f38;
}

.item_unavailable {
  background: #d20000;
  text-align: center;
  font-family: helvetica, sans-serif;
  position: absolute;
  left: 20%;
  top: 20%;
  color: #fff;
  margin-top: 0;
  margin-left: 0;
  width: 60%;
  height: 45px;
}

.red,
.price_inred {
  color: #d20000 !important;
}

.ques_mark,
.catalogEntryRecommendationWidget .ques_mark {
  background-image: url(../../images/ques_symbol.png);
  background-repeat: no-repeat;
  cursor: pointer;
  background-size: 100% 100%;
  height: 12px;
  margin: 0 0 -4px 3px;
  width: 12px;
  display: inline-block;
}

.tsc_error_message {
  margin: 5px;
  padding: 5px 5px 10px 5px;
  text-align: center;
  background: none repeat scroll 0 0 #d20000;
  font-weight: bold;
  color: #fff;
}

button.inactivity_popUp,
button.btn-success {
  margin: 0 auto;
}

#zipcode_content ul {
  padding-left: 20px;
}

.store_locator_frm_left {
  width: 57%;
  padding-left: 12px;
  margin-left: 6px;
  margin-right: 15px;
  float: left;
}

.store_locator_frm_right {
  width: 34%;
  float: left;
}

.store_or_txt {
  margin-top: 5px;
  margin-left:0px;
}

.store_locator_input {
  border: 1px solid #d7d7d7;
}

.store_locator_btn {
  padding: 8px 8px 25px 8px;
}

.breadcrumb_outer {
  margin: 0;
  padding: 0;
}

.menu-link-is-open #guestUserAccountcont_Mob .green_txt,
.menu-link-is-open #regUserAccountcont_Mob .green_txt {
  display: none;
}

.has-arrow {
  background: none;
  right: 3px;
  position: absolute;
  top: 1px;
  z-index: 5;
  font-size: 34px;
  font-weight: bold !important;
  color: #5f6d6e;
  cursor: pointer;
}

#rm-top #change_mob {
  padding-right: 16px;
}

#guestUserAccountcont_Mob p,
regUserAccountcont_Mob p {
  padding-right: 16px;
}

#pp_cont_mobile {
  display: none;
}

.overlay_button {
  background-color: #c3a98d;
  font-weight: bold;
}

@media (max-width: 767px) {
  #pp_cont_desktop {
    display: none;
  }

  #pp_cont_mobile {
    display: block;
  }

  .overlay_button {
    height: 0px;
    color: #000;
    font-size: 0.8rem;
  }
}

.btn-search {
  cursor: pointer;
}

.breadcrumb {
  font: normal 13px/16px  'Roboto', sans-serif;
  background:rgba(243, 243, 243, 1);
}

.category-img {
  width: 150px;
  height: 150px;
}

.category-h4 {
  font-size: 28px;
}

.breadcrumb_arrow {
  display: inline;
  width: 6px;
  height: 5px;
  margin-right: 5px;
  margin-left: 5px;
  font-size: 14px;
  color: #6c757d;
}

.category-h4 {
  font-size: 28px;
}

.category-border {
  border-right: 1px solid #c6c6c6;
  border-bottom: 1px solid #c6c6c6;
}

.category-border-right-no {
  border-right: none !important;
}

#seo-banner {
  margin: 6%;
}

@media (min-device-width: 768px) {
  .category-border-right-no {
    border-right: 1px solid #c6c6c6 !important;
    border-bottom: 1px solid #c6c6c6;
  }

}

@media (max-width: 767px) {
  .category-border {
    border: 1px solid #c6c6c6;
  }

  .category {
    width: 44%;
    display: flex;
    flex-direction: row;
    padding: .5rem;
    margin: .5rem;
  }
}

a:hover  {
  color: inherit;
}

li.breadcrumb-item > a:hover,
ol.breadcrumb a:hover {
  text-decoration: underline;
}

.ques_image_sc {
    cursor: pointer;
    display: inline-block;
    float: left;
    height: 20px;
    margin-left: 2px;
    margin-top: -2px;
    position: absolute;
    width: 20px;
}

.category h4.font-stymie {
  /*text-transform: uppercase;*/
  font-size: 16px;
  font-family: inherit;
  margin-bottom: 0;
  padding-top: 10px;
}

.category-h2{
    font-size: 1.5rem;
    letter-spacing: 0.05em;
}

.seo-bottom-link:hover {
  color: #d20000;
}

.ques_image{
   width: 12px;
   height: 12px;
}

/*ECOM-18757 start*/
.modal-body #checkAvail_jcontent.outer_container p.text {
  font-size: 11px;
}

/*ECOM-18757 end*/

/* ECOM-18298 starts */
 #storeChangeOverlay .modal-dialog .modal-content .modal-footer .btn.btn-secondary{
        line-height: 1.2;
  }

/* ECOM-18298 ends */

.modal-overlay,
.modal-backdrop {
  background:rgba(255, 255, 255, 0.9) none fixed repeat 0 0 !important;
  opacity: 1 !important;
}

/*ECOM-19185 START*/
@media (max-width: 767px) {

    .modal-header button.close,
    #availabilityModal button.close,
    #quickInfoPopup button.close {
    top:1px;
    right:1px;
  }
}

/*ECOM-19185 END*/

/* ECOM-19380 START */
.hollow-dots-spinner .dot {
      width: 15px;
      height: 15px;
      margin: 0 calc(15px / 2);
      border: calc(15px / 5) solid #d20000;
      border-radius: 50%;
      float: left;
      transform: scale(0);
      animation-name: hollow-dots-spinner-animation !important;
      animation-duration: 1000ms !important;
      animation-timing-function: ease !important;
      animation-delay: 0s;
      animation-iteration-count: infinite !important;
      animation-direction: normal !important;
      animation-fill-mode: none !important;
      animation-play-state: running !important;
}

.hollow-dots-spinner .dot:nth-child(1) {
  -webkit-animation-delay:-750ms;
  animation-delay:-750ms;
}

.hollow-dots-spinner .dot:nth-child(2) {
  -webkit-animation-delay:-500ms;
  animation-delay:-500ms;
}

.hollow-dots-spinner .dot:nth-child(3) {
  -webkit-animation-delay:-250ms;
  animation-delay:-250ms;
}

/* ECOM-19380 END */
svg.swipe{
  position: absolute;
  top: 41px;
  left: 0px;
  z-index: 100;
    font-size: 12px;
    /*font-stretch: condensed; */
  height: 15em;
  width: 29em;
  pointer-events: none;
}

svg.swipe.new-arrival {
  fill: #649430;
}

svg.swipe.on-sale {
  fill: #D20000;
}

svg.swipe.clearance {
  fill: #FFCB34;
}

svg.swipe.exclusive {
  fill: #000000;
}

svg.swipe.tsc-exclusive {
    fill: #000000;
}

.display1_cont svg.swipe{
  top:10px;
  left:-11px;
}

#list svg.swipe {
    top: 36px;
}

@media screen and (max-width: 1023px) {
    #list svg.swipe {
        top: -1px;
    }
}

 @media (max-width: 767px){
  svg.swipe{
    position: absolute;
      top: 0px;
      left: 0px;
      z-index: 400;
        font-size: 12px;
}

.search-result-image #mobProductImg svg.swipe{
    position: absolute;
    top: -2px;
    left: -24px;
    z-index: 400;
        font-size: 12px;

}
}

@media (min-width: 768px) and (max-width: 1023px){
.display1_cont svg.swipe{
  top:-10px;
  left:-11px;
}

}

#price_det_widjet_Id{
  z-index: 1051;
}

.invalid_input_field {
  border: 1px solid #d20000 !important;
}

.localization-modal button.disabled,
button.disabled {
    background-color: #d5d5d5;
    border: none;
    color: #919191;
    background-image:none !important;
    cursor: default;
}

.localization-modal button{
    line-height: 1.2;
}

.localization-modal input{
      float: left;
}

.localization-modal a{
    color: #005ebd;
    text-decoration: underline;
}

.localization button.btn.grey{
    line-height: 1.2;
}

/* Store delivery new pdp styles*/
#info_samedaydelivery{
  vertical-align: text-bottom;
}

.form-box.pdp-store-form label .font-normal{
    font-weight: normal;
}

.form-box.pdp-store-form label .font-normal.multipickuptxt{
    display: inline-flex;
}

.form-box.pdp-store-form label .boldTxt{
    display: contents;
}

.form-box.pdp-store-form label span::before{
   margin-top: 3px;
}

.greyBtnWrapper{
    width:100%;
    text-align: center;
    margin-top:15px;
}

.greyBtnWrapper button{
    width:55%;


}

.financingTxt a{
    text-decoration: underline;
}

a.trunk-rental {
    color: #807f7f;
    position: relative;
    top: 2px;
}

.greencheck{
    color: #6F9240;
    top: 2px;
    position: relative;
}

#mystore h3{
  font-size: 1.4em;
  letter-spacing: 0.04em;
}

#search_text_id{
    line-height: 1;
}

.notification-text {
  border: 1px solid #ce7100;
  color: #ce7100;
}

.tsc-sb.tsc_visible {
  height: auto;
  width: 700px;
  font: 300 22px "Oswald-Bold", sans-serif;
  overflow: visible;
  display: table;
  border: 1px solid #cfcfcf;
  max-height: 1000px;
  margin-top: 15px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  z-index: 950;
  position: absolute;
  left: 0px;
}

.tsc-sb.tsc_visible .tsc_left_pane {
  max-height: 1000px;
  overflow: visible;
  background: #fff;
  border-right: 1px solid #cfcfcf;
  display: table-cell;
  width: 30%;
  padding: 1.5%;
}

.tsc-sb .tsc_suggestions .tsc_title div {
    margin-top: 15px;
    margin-right: auto;
    margin-bottom: auto;
    margin-left: 20px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 22px;
  font-family: "Oswald-Bold";
  color: #000;
  font-size: 17px;
  line-height: 1;
}

.tsc-sb ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.tsc-sb .tsc_suggestions .tsc_list a {
  cursor: pointer;
  width: 100%;
  display: block;
  font-weight: normal;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  color: #333;
  box-sizing: border-box;
  text-transform: capitalize;
  text-decoration: none;
  border: 0;
    padding-top: 12px;
    padding-right: 12px;
    padding-bottom: 12px;
    padding-left: 20px;
}

.tsc-sb .tsc_products .tsc_product a {
  text-decoration: none;
}

.tsc-sb .tsc_list a:active,
.tsc-sb .tsc_list a:hover,
.tsc-sb .tsc_products .tsc_product a:active,
.tsc-sb .tsc_products .tsc_product a:hover {
  text-decoration: underline;
}

.tsc-sb .tsc_message .tsc_msg_results {
  font-size: 14px;
  text-decoration: none;
  text-transform: uppercase;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #737373;
    padding-top: auto;
    padding-right: auto;
    padding-bottom: 2px;
    padding-left: 5px;
  font-weight: bold;
  margin-top: 8px;
  text-transform: uppercase;
  color: #000;
  font-size: 17px;
  font-family: "Oswald-Bold";
  line-height: 2;
}

.tsc-sb .tsc_results {
  background-color: #fff;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  width: 70%;
  overflow: hidden;
  padding: 10px 20px 10px 20px;
  display: table-cell;
  vertical-align: top;
}

.tsc-sb .tsc_products .tsc_product {
  width: 30%;
    margin-top: 5px;
    margin-right: auto;
    margin-bottom: 10px;
    margin-left: auto;
  position: relative;
  padding: 1%;
  display: inline-block;
  vertical-align: top;
  text-align: center;
}

.tsc-sb .tsc_products .tsc_product a .tsc_pimage {
  margin: auto auto 5px auto;
  overflow: hidden;
  padding: 0;
  max-width: 100%;
}

.tsc-sb .tsc_products .tsc_product a .tsc_pimage img {
  display: inline-block;
  vertical-align: middle;
  font: 16px/1 'Roboto', sans-serif;
  width: auto;
  border: 1px solid #cfcfcf;
  height: 120px;
  max-width: 120px;
}

.tsc-sb .tsc_products .tsc_product .tsc_name {
  font-family: 'Roboto', sans-serif;
  color: #333;
  max-height: 43px;
  overflow: hidden;
  margin-bottom: 10px;
  font-weight: normal;
  font-size: 12px;
}

.tsc-sb.tsc_visible .tsc_more {
  display: block;
  text-align: center;
  margin: 20px;
}

.tsc-sb .tsc_more a {
  border: 1px solid #446621;
  background: rgba(0, 0, 0, 0) -moz-linear-gradient(90deg, #649430 0, #69ba04 100%) repeat scroll 0 0;
  background-image: -moz-linear-gradient(90deg, rgb(100, 148, 48) 0px, rgb(105, 186, 4) 100%);
  background-image: -webkit-linear-gradient(90deg, #649430 0, #69ba04 100%);
  background-image: -ms-linear-gradient(90deg, #649430 0, #69ba04 100%);
  text-decoration: none;
  padding: 10px;
  display: inline-block;
  border-radius: 4px;
  font: 16px  'Roboto', sans-serif;
  color: #fff;
  cursor: pointer;
  width: 200px;
  text-transform: capitalize;
  font-weight: bold;
  width: 175px;
  text-align: center;
}

.tsc-sb .tsc_more a:hover {
  border-color: #488406;
  background-image: -moz-linear-gradient(90deg, #59a20a 0, #77d306 100%);
  background-image: -webkit-linear-gradient(90deg, #59a20a 0, #77d306 100%);
  background-image: -ms-linear-gradient(90deg, #59a20a 0, #77d306 100%);
}


@media(max-width: 767px) {
    #search_text_id{
       color: #000;
    }

    .tsc-sb.tsc_visible{
        width: 100%;
    }

  .tsc-sb.tsc_visible .tsc_left_pane {
    width: 100%;
    border-right: 0;
    padding: 0
  }

  .tsc-sb .tsc_results,
  .tsc-sb .tsc_suggestions .tsc_title>div {
    display: none;

  }

  .tsc-sb .tsc_suggestions .tsc_list a {
    border-bottom: 1px solid #ccc;
    padding: 9px;
  }

}

.tsc_results .tsc_message .tsc_msg_noresult{
  font-weight: normal;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  color: #333;
  padding: 10px;
  }

#searchDropdown #AutoSuggestDiv .heading{
    background-color: #fff;
    font-weight: bold;
    color: #000;
    border-bottom: 1px dotted #cccccc;
    padding: 15px;
}

#autoSuggestDynamic_Result_div a.autoSuggestSelected{
  text-decoration: underline;
}

#searchDropdown #autoSuggest_Result_div #AutoSuggestDiv .list_section li > a{
    height: auto;
}

@media (min-width: 768px) and (max-width: 1024px){
    .tsc-sb.tsc_visible{
        width: 100%;
        margin-top: 0;
    }
}

.session-bar,
#clearInfo {
  width:100%;
  height: 50px;
  background: #ccc;
  line-height: 50px;
  box-sizing: content-box;
}

.session-bar-content {
  height: 50px;
  text-align: center;
  width: 100%;
  max-width: 1440px;
  margin:0 auto;
  font:normal 14px 'Roboto', sans-serif;
  line-height: 50px;
  color:#666;
}

.session-bar-content .lock {
  fill:#666;
  display: inline;
}

.session-bar-content p {
  margin:0;
  padding:0;
  margin-block-start:0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  position: relative;
  display: inline-block;
  height: 50px;
  line-height: 50px;
}

.session-bar-content p i{
  position: absolute;
  top:10px;
  left: -25px;
  fill:#666;
  line-height: 1;
  height: 24px;
  width: 24px;
  font-size: 24px !important;
}

.session-bar-content p a {
color:#005ebd;
text-decoration:underline;
}

#resetSessionConfirmation button {
    line-height: 1.2;
    border-radius: 4px !important;
}

#resetSessionConfirmation .modal-header {
  padding:0;
  height: 38px;
}

#resetSessionConfirmation .modal-title {
  width:100%;
}

/* Cookie acknowledgement */
.cookies-section {
    text-align: center;
    background: #F3F3F3;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 499;
    padding: 0.5em;
    color: #212529;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.cookies-section .cookie-text {
    margin: auto;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin-right: 1em;
    margin-left: 1em;
    text-align: center;

}

.cookies-section a:hover {
    color: #005ebd;
}

.cancel-svg {
    fill: #808080;
    cursor: pointer;
}

@media screen and (max-width: 375px) {
    .cookies-section {
        bottom: 34px;
        font-size: 11px;
        line-height: 14px;
    }
}

@media screen and (max-width: 767px) and (min-width: 376px) {
    .cookies-section {
        bottom: 0;
        font-size: 11px;
        line-height: 14px;
    }
}

@media screen and (max-width: 1279px) and (min-width: 768px) {
    .cookies-section {
        bottom: 40px;
        font-size: 11px;
        line-height: 14px;
    }
}

.mobile_menu .nav-link {
   color: #000;
}

/* Cookie disabled */
.cookie-disabled {
    text-align: center;
    background: #ccc;
    width: 100%;
    z-index: 1000;
    padding: .5em;
    color: grey;
}

@media (max-width: 767px) {
    .cookie-disabled {
        font-size: 11px;
        line-height: 14px;
    }
}


/*Desktop category flyout : starts*/
/*Main style*/
body.mmactive {
    overflow: hidden;
    position: fixed;
}

.desktop_menu {
    display: none;
    position: relative;
    font-size: 1rem;
    width: 350px;
    margin-left: -27px;
    padding-bottom: 35px;

    margin-right: 20px;
}

.desktop_menu.sub-open {
    height: 100%;
    overflow: hidden;
    position: fixed;
}

/*.desktop_menu.sub-open > li {
    border: none;
}*/

.sm_menu_outer_desktop.active .desktop_menu {
    display: block;
}

.sm_menu_outer_desktop .desktop_menu li.back a {
    padding: 12px 0px 12px 10px;
}

.sm_menu_outer_desktop .desktop_menu a {
    display: block;
    text-decoration: none;
}


.sm_menu_outer_desktop .desktop_menu li.active > .submenu {
    left: -20px;
}

.sm_menu_outer_desktop.sm_menu_scroll .desktop_menu li.active > .submenu {
    left: -28px;
}

.sm_menu_outer_desktop .desktop_menu li {
    list-style-type: none;
    color: #fff;
    width: 100%;
    /*margin: 0 1.8rem;*/
    /* display: inline-table; */
    /* width: 100%; */
    /* vertical-align:  middle; */
}

.sm_menu_outer_desktop.sm_menu_scroll .desktop_menu li {
    width: 97%;
}

/*
.sm_menu_outer .desktop_menu li.hasChild > a {
  background: url("../images/next.png") no-repeat scroll 95% center/5% auto;
}
*/
/*Hamburger Animation */
#desktop_menu {
    cursor: pointer;
    float: right;
    height: 35px;
    position: fixed;
    right: 5px;
    transform: rotate(0deg);
    transition: all 0.5s ease-in-out 0s;
    width: 35px;
    top: 10px;
    left: 9px;
    z-index: 1040;
}


#desktop_menu div {
    display: none;
    color: #fff;
    font: bold 18px 'Roboto', sans-serif;
    text-align: center;
}

.desktop_menu a:hover {
    color: #212529;
}

.sm_menu_outer_desktop .desktop_menu li.hasChild {
    position: relative;
}

.sm_menu_outer_desktop .desktop_menu li.hasChild > a:after {
    content: "";
    border: solid 1px #ccc;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 5px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    float: right;
    margin-top: 5px;

}

.desktop_menu .menu-nav-item {
    border-bottom: 1px dotted #ccc;
    list-style: none;
    padding: .75rem 0;
}


.sm_menu_outer_desktop .desktop_menu a {
    display: block;
    text-decoration: none;
    font-size: 14px;
    width: 100%;
    padding: 0;
}

.desktop_menu li.back {
    position: relative;
}

li.menu-nav-item.show-more-main {
    padding-left: 12px !important;
}


/* helper classes */
body {
    width: 100%;
}

.noscroll,
body.no-scroll {
    position: fixed;

    overflow: scroll;


}

.sm_menu_outer_desktop .border-bottom-grey {
    border-bottom: 1px dotted #ccc;
}


.sm_menu_outer_desktop .desktop_menu .menu-nav-link-item {
    color: #005ebd;
    text-decoration: underline;
}

.sm_menu_outer_desktop .desktop_menu li.menu-nav-item-heading {
    border-bottom: 1px dotted #ccc;
    list-style: none;
    padding: 0.75rem 0;
    color: #000;
    font-size: 14px;
}

.sm_menu_outer_desktop .desktop_menu .menu-nav-item-heading a {
    display: inline;
}

.desktop_menu .left-arrow:before {
    content: "";
    border: solid 1px #ccc;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 5px;
    transform: rotate(134deg);
    -webkit-transform: rotate(134deg);
    position: relative;
    left: -9px;
    top: 2px;

}

.desktop_menu .last-item {
    border-bottom: none;
}

.desktop_menu .show-more {
    position: relative;
    left: -12px;
    top: -4px;
}

.desktop_menu .show-more:before {
    content: "+";
    color: #ccc;
    /* padding: 5px; */
    font-size: 25px;
    /* font-weight: bold; */
    position: relative;
    top: 4px;
    left: -2px;
}

.sm_menu_outer_desktop .desktop_menu li.hasChild:first-child {
    border-bottom: 1px dotted #ccc;
}

.desktop_menu .menu-nav-item.show-more-main {
    padding: 4px 4px 4px 12px;

}

.desktop_menu .shopall-link {
    font-weight: normal;
    margin-left: 5px;
}

.sm_menu_outer_desktop {

    overflow-y: scroll;
    overflow-x: hidden;
    position: fixed;

    top: 125px;
    z-index: -9;
    width: 350px;
    height: calc(100% - 125px);
    opacity: 0;
    transition: opacity 0.2s;
    /* margin: 0 20px; */
}

.sm_menu_outer_desktop.active {
    background: #fff;
    opacity: 1;
    z-index: 1031;
}

/*Sub Menu anim */
.sm_menu_outer_desktop .desktop_menu .submenu {
    background-color: #fff;
    height: calc(100% - 125px);
    position: fixed;
    right: -103%;
    top: 125px;
    /*  transition: all 0.5s ease 0s;*/
    width: 350px;
    z-index: 999;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-bottom: 125px;


}

.sm_menu_outer_desktop.sm_menu_scroll {
    overflow-y: hidden;
}

.sm_menu_outer_desktop.sm_menu_scroll .submenu .menu-nav-item {
    padding-left: 20px;
}

.sm_menu_scroll {
    width: 332px;
}

.sm_menu_scroll .desktop_menu .submenu {
    width: 378px;
}

.sm_menu_outer_desktop .desktop_menu .submenu.overflowHidden {
    overflow: hidden;
}

.tabletShow{
   	display : none;
}

.flyout-backdrop {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 498;
    display: none;
    overflow: hidden;
    outline: 0;
}

.menupointer:after {
    content: "";
    width: 0px;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #fff;
    position: absolute;
    top: 35px;
    left: 6px;
    z-index: 502;
}

.menupointer {
    display: none;
}

header{
    z-index: 500;
    position: relative;
}

@media (min-width: 767px) and (max-width: 1024px) {

    .sm_menu_outer_desktop {
        top: 139px;
        height: calc(100% - 139px);
    }

    .sm_menu_outer_desktop .desktop_menu .submenu {
        top: 139px;
        height: calc(100% - 139px);
    }

    .tabletShow{
    	display : block;
    }
}

@media not all and (min-resolution:.001dpcm) {
    @supports (-webkit-appearance: none) {
        .sm_menu_outer_desktop.sm_menu_scroll .desktop_menu .submenu li {
            width: 90%;
        }
    }
}

@supports (-webkit-overflow-scrolling: touch) {
    .sm_menu_outer_desktop.sm_menu_scroll .desktop_menu .submenu li {
        width: 90%;
    }
}


/*Desktop category flyout : ends*/


/*CHECKOUT REDESIGN COMMON STYLES (MODALS,PDP,ETC)*/
.fullscreen-modal {
    width: 100%;
    padding: 0 !important;
    height: 100vh;
}

.fullscreen-modal .modal-dialog {
    max-width: 600px;
    margin: 0 auto;;
    width: 100%;
    padding: 0;
    min-height: 100%;
}

.fullscreen-modal .modal-content {
    height: 100vh;
    padding-bottom:15px;
    border: none;
    position: relative;
    overflow: hidden;
}

.modal-content a {
    color: #005ebd;
}

.fullscreen-modal .modal-header {
    width: 100%;
    height: 55px;
    background: #fff;
    box-shadow: 3px 3px 17px rgba(0, 0, 0, .1);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
}

.fullscreen-modal .modal-body {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

.fullscreen-modal .modal-header button.close {
    width: 22px;
    height: 22px;
    background: url("../../images/gray-cancel.png") 0 0 no-repeat;
    background-size: 22px;
    top: 17px;
    right: 15px;
}

.fullscreen-modal .modal-header button.close:before,
.fullscreen-modal .modal-header button.close:after {
    display: none;
}

.fullscreen-modal .modal-header .text-link {
    position: absolute;
    height: 55px;
    top: 0;
    left: 0;
    padding: 0 0 0 15px;
    font: normal 14px 'Roboto', sans-serif;
    line-height: 55px;
}

.fullscreen-modal .modal-header .text-link a {
    color: #005ebd;
    text-decoration: underline;
}

.fullscreen-modal .modal-body .container {
    width: 100%;
    margin: 0 auto;
    max-width: none;
}

.item-info {
    padding: 0 0 15px 0;
    border-bottom: solid 1px #ccc;
}

.fullscreen-modal .modal-body .img-holder img {
    width: 100%;
    max-width: 100%;
    height: auto;
    display: block;
}

.fullscreen-modal .modal-body .item-title {
    width: 60%;
    display: inline-block;
    margin: 0;
    padding: 0;
}

.fullscreen-modal .modal-body .item-title h2 {
    color: #000;
    font-size: 14px;
    text-transform: capitalize;
    font-weight: bold;
}

ul.details {
    list-style: none;
    margin: 0;
    padding: 0;
}
.form-control {
    border: solid 1px #ccc;
}

.form-control:focus {
}

.fullscreen-modal .change-zip {
    padding: 20px 0 0 0;
}

.fullscreen-modal .change-zip p {
    text-align: left;
    font: bold 14px 'Roboto', sans-serif;
}

.fullscreen-modal .change-zip form {
    padding-top: 15px;
}

.fullscreen-modal input.form-control {
    height: 38px;
    font: normal 14px 'Roboto', sans-serif;
}

.fullscreen-modal .switch-link {
    font: normal 14px 'Roboto', sans-serif;
    text-decoration: underline;
    color: #005ebd;
}

.fullscreen-modal .promo-offers {
    padding-top: 30px;
}

.fullscreen-modal .promo-offers .offer {
    font: normal 14px 'Roboto', sans-serif;
    color: #649430;
    text-align: left;
    margin-bottom: 15px;
}
#promotion-more-link {
    margin-bottom: 4px !important;
}
.fullscreen-modal .promo-offers .offer .notice {
    font-size: 11px;
    font-style: italic;
    display: block;
}

.fullscreen-modal a.show-offers {
    color: #649430;
    text-decoration: none !important;
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}

[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
    position: relative;
    padding-left: 22px;
    cursor: pointer;
    line-height: 22px;
    display: inline-block;
    color: #333;
    font-size: 14px;
}

[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 2px;
    width: 18px;
    height: 18px;
    border: 2px solid #ccc;
    border-radius: 100%;
    background: #fff;
}

[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 10px;
    height: 10px;
    background: #649430;
    position: absolute;
    top: 6px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.customized-select {
    position: relative;
}

.customized-select select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
    background-color: transparent !important;
    position: relative;
    z-index: 10;
}

.customized-select:after {
  content: '';
  top: 2px;
  position: absolute;
  right: 0;
  display: inline-block;
  vertical-align: middle;
  background: url(../../images/TSC-arrow.png) no-repeat 96% center;
  height: 30px;
  padding: .2em 2.5em .2em .8em;
  background-size: 25px;
  font-size: 11px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
  overflow: hidden;
  color: #000;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;

}

.customized-select select::-ms-value {
    background: transparent !important; /* remove blue background on ie10/ie11 when selected*/
}

.custom-text label {
    padding-left: 0 !important;
}

.custom-text label:after,
.custom-text label:before {
    display: none;
}

p.shipping-disclaimer {
    padding-top: 15px;
    font: italic 11px 'Roboto', sans-serif !important;
    font-size:11px !important;
}

button.btn-primary {
    border-radius: 4px;
    cursor: pointer;
    display: block;
    font-family: 'Roboto', sans-serif;
    font-size: 16px !important;
    height: 40px;
    letter-spacing: 0;
    line-height: normal;
    max-width: 300px;
    min-width: 125px;
    padding: 0 1% !important;
    margin: 0 auto;
    text-decoration: none;
    text-transform: none;
    white-space: nowrap;
    background: #fff;
    border: 2px solid #649430;
    color: #649430;
    font-weight: bold;
}

button.btn-primary:hover,
button.btn-primary:active {
    background: #649430 !important;
    color: #fff !important;
    border: 2px solid #649430 !important;
    outline: none !important;
    box-shadow: none ! important;
}
.bonus-buy {
    color: #649430;
    border-bottom: solid 1px #ccc;
}
.mobileCart .bonus-buy {
    padding-top: 40px !important;
}
.bonus-buy-header {
    position: relative;
    padding-left: 37px;
    line-height: 1;
}

.bonus-buy-header .bonus-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 35px;
}

.bonus-buy-header .bonus-icon svg {
    height: 35px;
}

.bonus-buy-header p {
    padding-top: 5px;
}

.bonus-buy ul {
    margin-bottom: 1rem;
}

.bonus-buy .bonus-buy-link {
    padding-top: 15px;
}

.bonus-buy ul li {
    margin-bottom: 15px;
}
/* Custom checkbox starts */

/* Customize the label (the container) */
label.container {
    position: absolute;
    top: 7px;
    left: 0;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 20px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
label.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
label.container .checkmark {
    position: absolute;
    top: 2px;
    left: 5px;
    height: 16px;
    width: 16px;
    border: 1px solid #808080;
}

/* When the checkbox is checked, add a green background */
.container input:checked ~ .checkmark {
    background-color: #649430;
}

/* Create the checkmark/indicator (hidden when not checked) */
label.container .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
    left: 5px;
    top: 1px;
    width: 5px;
    height: 11px;
    border: solid #fff;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

/* Custom checkbox ends */

.col-1.width-cap {
    max-width: 20px;
}
#storeAvailabilityModal .store-availability .col-1.width-cap svg {
  position: absolute;
  top: 4px;
}
.power-plus-add-on {
    width: 100%;
    border: solid 1px #ccc;
    margin: 15px 0 0 0;
}

.shipping-delivery-options .container.power-plus-add-on .row {
    border: none !important;
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
}

.power-plus-add-on col-9 strong {
    line-height: 1;
}

.power-plus-add-on .option-chosen {
    position: relative;
}

.power-plus-add-on .option-chosen a.remove-pplus {
    top: 2px;
    right: 0;
    position: absolute;
}

.power-plus-add-on .option-chosen a.remove-pplus svg {
    width: 18px;
    height: 18px;
    fill: #333;
}

.power-plus-add-on .qty-wrapper {
    padding: 15px 0 0 0;
}

a.closed,
a.opened {
    font: bold 14px 'Roboto', sans-serif;
    color: #000;
}

a.open-close .plus {
    display: none;
}

a.closed .minus {
    display: none;
}

a.closed .plus {
    display: inline;
}

.svg-icon {
    display: inline-flex;
    align-self: center;
}

.svg-icon svg {
    height: 16px;
    width: 16px;
}

.svg-icon.svg-baseline svg {
    top: .15em;
    position: relative;
}

.pt-1.pb-1.svg-icon.svg-baseline-livebee{
     display: none;
}

svg.green {
    fill: #649430;
}

svg.red {
    fill: #d20000;
}

svg.grey {
    fill: #808080;
}

svg.force-svg {
    width: 16px;
    height: 16px;
}

svg.info-svg {
    fill: #666;
}

button.green {
    color:#fff !important;
    border: solid 1px #446621;
}

button.green-border {
    background: #fff;
    border: 2px solid #649430;
    color: #649430;
    font-weight: bold;
}

button.green-border:hover {
    background: #649430;
    color: #fff;
}

.container.recommendations {
    padding: 0;
    max-width: 300px;
}

.container.recommendations .carousel {
    border: solid 1px #ccc;
}

.container.recommendations .carousel-header {
    position: relative;
    text-align: center;
    width: 235px;
    height: 40px;
    margin: 0 auto;
}

.container.recommendations .carousel-header h2 {
    font: bold 14px 'Roboto', sans-serif;
    height: 40px;
    line-height: 40px;
}

.container.recommendations .carousel-inner {
    height: 190px;
}

.carousel-item {
    padding-top: 20px;
}

.carousel-item .container {
    padding: 0;
    width: 90%;
}

.carousel-item .swipe {
    top: 0;
    left: 0;
}

.carousel-indicators {
    position: static;
}

.carousel-indicators li {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    border: solid 1px #808080;
    background: #fff;
}

.carousel-indicators li.active {
    background: #808080;
}

a.carousel-control-next,
a.carousel-control-prev {
    width: 25px;
    height: 40px;
    opacity: 1;
}

a.carousel-control-next::after,
a.carousel-control-prev::after {
    content: "";
    width: 12px;
    height: 12px;
    display: block;
    border-bottom: 4px solid #808080;
    border-right: 4px solid #808080;
}

a.carousel-control-next::after {
    transform: rotate(316deg);
}

a.carousel-control-prev::after {
    transform: rotate(-226deg);
}
.star-ratings-css-top {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0;
    overflow: hidden;
    color: #e7711b;
    z-index: 1;
}

.container.recommendations .star-ratings-css-bottom {
    padding: 0;
    z-index: 0;
}

.container.recommendations .star-ratings-sprite {
    background: url("../images/star-rating-sprite_75.png") repeat-x;
    font-size: 0;
    height: 21px;
    line-height: 0;
    overflow: hidden;
    text-indent: -999em;
    width: 86px;
    display: inline-block;
    margin-top: 3px;
}

.container.recommendations .star-ratings-sprite-rating {
    background: url("../images/star-rating-sprite_75.png") repeat-x;
    background-position: 0 100%;
    float: left;
    height: 16px;
    display: block;
}

.container.recommendations .rating_count_pdp_sum {
    display: inline-block;
    margin: 1px 0 0 4px;
    font-size: 14px;
}

.carousel-item .product-title a {
    font: bold 14px 'Roboto', sans-serif;
    color: #000;
    text-decoration: none !important;
    line-height: 14px;
}

.carousel-item .item-price {
    font: bold 14px 'Roboto', sans-serif;
}

.carousel-item .old {
    font: normal 12px 'Roboto', sans-serif;
    color: #ccc;
    text-decoration: line-through;
}

.carousel-item .was-price .save {
    font: normal 12px 'Roboto', sans-serif;
    color: #d20000;
}
.info-link svg,
svg.info-svg {
    width: 14px;
    height: 14px;
    left: .25em;
    top: .15em !important;
    cursor: pointer;
}

/**Generic Modal Styles**/
.modal-body.generic {
    padding-top: 65px;
    font: normal 14px 'Roboto', sans-serif;
    line-height: 1.5rem;
}

.modal-body p {
    font-size: 14px !important;
}

.modal-body p.shipping-disclaimer {
    font-size:11px !important;
}

.modal-body.generic p {
    padding-bottom: 10px;
}

.modal-body.generic p.border {
    border: solid 2px #000 !important;
    padding: 10px;
}

.modal-body.generic p.fine-print {
    font-size: 10px;
}

.modal-body.generic ul {
    padding: 0 0 10px 10px;
    list-style: none;
}

.modal-body.generic ul li {
    padding-bottom: 5px;
}

.modal-body.generic h2 {
    font: bold 14px 'Roboto', sans-serif;
}

.modal-body.generic h3 {
    font: bold 14px 'Roboto', sans-serif;
}

.fullscreen-modal .col-12 {
    padding: 0;
}

/**ADD TO CART MODAL EXTENSION**/
.fullscreen-modal.add-to-cart-modal .modal-header {
    box-shadow: none !important;
    border: none;
}

.fullscreen-modal.add-to-cart-modal .modal-body {
    padding: 0;
    padding-top: 210px;
    padding-bottom: 25px;
}

.fullscreen-modal.add-to-cart-modal.choose-options-modal .modal-body {
    padding: 0;
    padding-top: 120px;
    padding-bottom: 25px;
}

.fullscreen-modal.add-to-cart-modal .modal-body .add-to-cart-buttons {
    width: 100%;
    border-bottom: 1px solid #e9ecef;
    box-shadow: 5px 10px 17px rgba(0, 0, 0, .1);
    margin-bottom: 15px;
    background: #fff;
    position: fixed;
    top: 55px;
    z-index: 499;
}

.add-to-cart-buttons-content {
    padding: 0 15px;
}

.see-price {
    font: normal 11px 'Roboto', sans-serif;
    color: #d20000;
}

.a2c-success {
    font: bold 14px 'Roboto', sans-serif;
    line-height: 20px;
}

.add-to-cart-modal ul.bonus-buy-offers {
    list-style: none;
    padding: 15px 0 0 0;
}

.add-to-cart-modal ul.details .red {
    font-size: 10px;
}

.color-select label {
    display: inline-block;
    position: relative;
    border: solid 1px #ccc;
    margin-bottom: 4px;
    padding-left: 0 !important;
}

.color-select label img {
    padding: 2px;
    outline: none !important;
}

.color-select input[type="radio"] {
    display: none;
}

.color-select label .after {
    display: none;
}

.color-select label.disabled .after {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.color-select label.disabled img {
    opacity: .1;
}

.color-select input[type="radio"]:checked + label {
    border: solid 1px #000;
    outline: none;
}

span.color-choice {
    font-weight: normal;
}

.color-select label:before,
.color-select label:after {
    display: none;
}

.size-select {
    border-radius: 0;
}

.pickup-only {
    position: relative;
    padding: 8px 0 15px 23px;
}

.pickup-only .checkmark {
    left: 0;
}

.pickup-only p {
    font: normal 14px 'Roboto', sans-serif !important;
}

.availability-switch-link {
    text-align: center;
}

ul.stores-list {
    list-style: none;
    padding: 0 !important;
}

ul.stores-list li:not(:last-child) {
    border-bottom: dotted 1px #ccc;
}

ul.stores-list .distance, ul.stores-list .pickup-store {
  line-height:1.5em;
}
a.store-detail-toggle {
    font: normal 14px 'Roboto', sans-serif;
    text-decoration: none;
    color: #000 !important;
}

.hours-of-operation {
    padding-top: 15px;
}

.store-availability .pickup-store {
    padding-left: 0;
}
.store-availability button.green-border {
  max-width: 130px !important;
}
.pickup-store-details.open-close-target {
    padding-top: 15px;
}
.store-availability .col-6.p-0:not(.pr-1){
  text-align:right !important;
}
.current-store {
    color: #649430;
    padding-bottom: 10px;
}

.red,
.price_inred {
  color: #d20000 !important;
}

.common {
display:inline;
}

p.pickup-store-disclaimer {
    font: italic 11px 'Roboto', sans-serif;
}

.strike, .strike-new {
    text-decoration: line-through;
}

.cart-price {
    font: normal 10px 'Roboto', sans-serif;
    color: #d20000;
}

#checkAvailabilityBOPISModal ul.details,
#checkAvailabilityNONBOPISModal ul.details {
    padding-left: 0 !important;
}

.choose-options-info h2 {
    color: #000;
    font-size: 14px;
    text-transform: capitalize;
    font-weight: bold;
    line-height: normal;
}

.delivery-restriction {
    padding-top: 15px;
    font: italic 11px 'Roboto', sans-serif;
    padding-left: 5px;
    word-break: break-all;
}

.pickup-store {
    padding: 15px 0 15px 22px;
}
.pickup-store .store-phone {
    padding-top: 15px;
}
/* END CHECKOUT REDESIGN COMMON STYLES*/
/*MEDIA QUERIES FOR CHECKOUT REDESIGN COMMON STYLES*/
@media screen and (min-width: 768px) and (min-device-width: 1000px) and (min-height:400px) {

    .fullscreen-modal .modal-content {
        border-left: 1px #f3f3f3 solid;
        border-right: 1px #f3f3f3 solid;
    }

    .power-plus-add-on {
        max-width: 300PX !important;
    }
    .container.recommendations .carousel-header {
        position: relative;
        text-align: center;
        width: 235px;
        height: 40px;
        margin: 0 auto;
    }

    .container.recommendations .carousel-header h2 {
        font: bold 14px 'Roboto', sans-serif;
        height: 40px;
        line-height: 40px;
    }

    .container.plcc-promo {
        width: 300px;
    }

    .sms-opt-in {
        padding-bottom: 15px;
    }

    .add-to-cart-buttons-content {
        max-width: 320px;
        margin: 0 auto;
    }

    .modal-body .container.power-plus-add-on,
    .modal-body .container.recommendations {
        margin: 0;
    }

    .product-details-button button {
        width: 300px;
        margin: 0 auto;
        display: block;
    }
}

@media screen and (min-width: 768px) {
    .power-plus-add-on {
        max-width: 300PX !important;
    }

    #confirm-create-account button,
    #deliveryModalCheckout button,
    #pickupModalCheckout button,
    #truckDeliveryModal button,
    #editDeliveryOptionsModal button {
        max-width: 300px !important;
        margin: 0 auto;
        display: block;
    }
}

/*START Cart Buttons Landscape Hack*/
@media screen and (max-width:1023px) and (orientation:landscape) and (min-aspect-ratio: 13/9) {
  /*button:not(#soln-submit):not(#soln-cancel):not(#soln-clearForm):not(.overlay-btn):not(.np-svg):not(#create_close_button):not(#edit_close_button):not(#delete_close_button):not(#editShoppingListPopup_save):not(#editShoppingListPopup_cancel):not(#deleteShoppingListPopup_save):not(#deleteShoppingListPopup_cancel) {
        max-width: 300px !important;
        margin: 0 auto;
        display: block !important;
    }*/

    #cart-buttons .col-md-6,
    .add-to-cart-buttons .col-md-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
    #cart-buttons .col-md-6,
    .add-to-cart-buttons .pr-lg-2 {
        padding-right:5px !important;
    }
}
/*END MEDIA QUERIES FOR CHECKOUT REDESIGN COMMON STYLES*/
.capitalize-text {
	text-transform: capitalize;
}

.modal-delivery-restriction {
    font: italic 11px 'Roboto', sans-serif;
    word-break: break-all;
}

span.free {
    color: #649430;
    font-weight: bold;
}

.pickup-store {
   padding: 0 0 15px 23px;
}

a.delivery-text-link {
    padding-left: 24px;
}

a.underline-link:hover {
    color: #005ebd;
}

.color-select [type="radio"]:checked + label:before,
.color-select [type="radio"]:not(:checked) + label:before,
.color-select [type="radio"]:checked + label:after,
.color-select [type="radio"]:not(:checked) + label:after {
    content: none;

}

.color-select [type="radio"]:checked + label,
.color-select [type="radio"]:not(:checked) + label {
    padding: 0;
}

.border-top-grey {
    border-top: 1px dotted #ccc;
}
.store-availability li.LimitedQtyMsg .pickup-store {
	width: 100%;
}
.store-availability li.LimitedQtyMsg .pickup-store .width-cap {
	float: left;
}
/*.modal-open {
    position: fixed;
    overflow: hidden;
}*/
.font-xs {
    font-size: 11px;
}

#quickInfoPopup.fullscreen-modal.add-to-cart-modal .modal-body .add-to-cart-buttons {
        left: 0;
    }


#quickInfoPopup .modal-body {
    margin-top: 55px;
    padding-top: 0;
    font-size: 14px;
    overflow-x: hidden;
}

#quickInfoPopup .modal-body button {
    width: 250px !important;
    margin: 0 auto;
}

#quickInfoPopup ul.details{
    line-height: 22px;
    padding-bottom: 1em;
}

.choose-options-info svg.swipe{
    top: 0;
}

a[role="button"],
.container-fluid a[role="button"] {
    text-decoration: none !important;
    color: unset;
}

@media screen and (min-width: 1024px) and (orientation:landscape) {
  @supports (-webkit-overflow-scrolling: touch) {
    #delOptionsModalNew .action-button {
        padding-bottom: 50px;
    }
  }
}

.choose-options-modal .customized-select{
    padding-bottom: 1em !Important;
}
.choose-options-modal .lb_txt1{
    display: inline;
}

.error_message_span,
.error-msg{
    color: #d20000;
}

@media screen and (orientation:landscape) and (min-width: 320px) and (max-width: 764px) {
    .choose-options-modal .modal-body #quickInfoRefreshArea {
        padding-bottom: 2em;
    }
}

.customized-select select::-ms-expand{
     display: none;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .modal-open {
	  -ms-overflow-style: -ms-autohiding-scrollbar;
	}
    #storeAvailabilityModal .container.change-zip,
    #storeAvailabilityModal .container.store-availability
    {
        width:95%;
    }
}

a.mobile-only {
    color: #000;
    text-decoration: none !important;
}
  #quickInfoPopup.fullscreen-modal.add-to-cart-modal .modal-header .add-to-cart-buttons {
        left: 0;
    }

    .fullscreen-modal.add-to-cart-modal .modal-header .add-to-cart-buttons {
        width: 100%;
        border-bottom: 1px solid #e9ecef;
        box-shadow: 5px 10px 17px rgba(0, 0, 0, .1);
        margin-bottom: 15px;
        background: #fff;
        position: fixed;
        top: 55px;
        z-index: 499;
    }

    #quickInfoPopup .modal-header #WC_QuickInfo_Link_addtocart button {
        width: 250px !important;
        margin: 0 auto;
    }


@media screen and (orientation:landscape) {
  .modal-body.generic.android-tab {
      padding-bottom: 120px;
  }
}
#storeAvailabilityModal .modal-body.generic.android-tab {
  padding-bottom: 120px;
}
#tscShippingAddresses_modal .modal-body.generic.android-tab {
  padding-bottom: 120px;
}

@media screen and (orientation:landscape), screen and (min-width: 768px) and (orientation:portrait) {
  @supports (-webkit-overflow-scrolling: touch) {
    #tscShippingAddresses_modal .modal-body.generic {
      padding-bottom: 120px;
    }
  }
}

ul#social-links {
    list-style: none;
}

ul#social-links li {
    display: inline-block;
    padding-right: 3px;
}

ul#social-links li a {
    display: block;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 24px;
    font-size: 24px;
    color: #fff;
}

ul#social-links li .twitter {
    color: #4DC8F1;
}

ul#social-links li .facebook {
    color: #3B5998;
}

ul#social-links li .instagram {
    color: #3E739D;
}

ul#social-links li .pinterest {
    color: #BD081C;
}

ul#social-links li .email {
    color: #7F47DD;
}
.f-11,
.store-pricing .f-11 {
    font-size: 11px !important;
}
.mt-2 {
    margin-top: .5rem !important;
}
.store-pricing {
    line-height: 21px;
}
.pickupmsg {
	width: 29em;
}



button#editAddrsContinue {
    width: auto;
}
input#ncpTandC {
width: 13px;
height: 13px;
margin-right: 3px;
margin-top: 3px;
}
button#btnRegSubmit {
    padding-top: 12px;
	width: auto;
}
button#btnRegCancel{
    width: auto;
}
tbody#avsTableBody h4 {
    font: bold 16px 'Roboto', sans-serif;
}
#footerDisplay div.third_container{
    padding-bottom: 40px;
}

@media screen and (max-width: 767px){
    #footerDisplay div.third_container{
    padding-bottom: 80px;
}
.sl-search .carousel-item {
  margin: 0 !important;
}
}

/*my store navigation styles */

#mystore1.store-overlay h2,
#mystore1.store-overlay h4 {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 1rem;
}

#mystore1.store-overlay a {
    text-decoration: underline;
    color: #005EBD;
    display: block;
    margin: 1rem 0;
}

.store-overlay {
    /*box-shadow: 0px 20px 17px rgba(0, 0, 0, .1);*/
    position: absolute;
    width: 100%;
    background: #fff;
    padding: 20px 0 0;
    /*overflow: hidden;*/
    z-index: 500;
    line-height: 21px;
}

.store-overlay .right-panel svg {
    height: 2.2em;
    width: 120px;
}

.border-right-dotted {
    border-right: 1px dotted #cccccc;
}

.border-right {
    border-right: 1px solid #cccccc;
}

.google-map-section {
    min-height: 400px;
}

.store-overlay #findStores input,
.store-overlay #findStores input[type="text"]{
    border-radius: 4px !important;
    line-height: normal !important;
}

.find_zipcode_tablet .find_zipcode .col-12 {
    border-top: 1px solid #cccccc;
}

input {
    border: 1px solid #cccccc !important;
}

.find_zipcode .t-wrap {
    margin-top: 3.5rem;
}

.find_zipcode_tablet .find_zipcode .t-wrap {
    padding-top: 1rem;
}

.find_zipcode .col-12,
.right-panel .col-12{
    padding-left: 15px !important;
    padding-right: 15px !important;
}
.store-no {
    font-weight: normal;
    font-size: 11px;
}

@media screen and (max-width: 1200px) {
    .border-right-dotted {
        border-bottom: 1px dotted #cccccc;
        border-right: 0;
    }
}

/** My store navigation styles ends **/

/** Search results for zip code */

.tsc-container {
    background-color: #fff;
    position: absolute !important;
    z-index: 499;
    border-radius: 2px;
    font-family: 'Roboto', sans-serif;
    box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.3);
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    overflow: hidden;
    display: none;
    left: 16px;
}

.tsc-item {
    cursor: pointer;
    padding: 0 8px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 34px;
    text-align: left;
    border-top: 1px solid #ccc;
    font-size: 12px;
    color: #000000;
    text-transform: capitalize;
}

.place-icon {
    width: 20px;
    height: 20px;
    margin-right: 0px;
    margin-top: 5px;
    display: inline-block;
    vertical-align: top;
    fill: #808080;
}

#findStoresOverlay input{
    border-radius: 4px;
    line-height: normal;
}

.store-overlay .tsc-container{
    margin-top: 38px;
}

.tsc-container div.selected {
    background-color: #ccc;
}

#invalid_zipCityStateOverlay{
    margin-left: 15px;
}

.store-overlay input:focus,
.store-find input:focus{
    box-shadow: none !important;
    color: #495057 !important;
    background-color: #fff !important;
    border-color: #a7a9af !important;
}

#makeMyStoreOverlay .btn.btn-secondary,
#makeMyStoreOverlay .btn.btn-success{
   line-height: 1.2;
}

#storeChangeOverlay .btn.btn-success,
#storeChangeOverlay .btn.btn-secondary {
    min-width: 120px !important;

}

#storeChangeOverlay .modal-footer .row div:first-child {
    margin-right: 20px;
}

#tscDefaultCard_modal .new-payment-button{
    text-align: center;
}

/* Assemble it styles strat here */

.powerplus + .assemble-it {
    border-top: 1px dotted #ccc;
    padding-top: 1rem;
    margin-top: 1rem;
}

.assemble-it svg.build {
    height: 60px;
    width: 60px;
    fill: #649430;
}

.assemble-it .icon-info {
    fill: #666;
    position: relative;
    top: 0.13em;
}

.assemble-it .option-chosen {
    position: relative;
}

.assemble-it .remove-assemble {
    top: 2px;
    right: 0;
    position: absolute;
}

.assemble-it .qty-wrapper {
    padding: 15px 0 0 0;
}

.assemble-it a.remove-assemble svg {
    width: 18px;
    height: 18px;
    fill: #333;
}

.icon-not-available {
    fill: #cccccc;
    width: 20px;
    height: 20px;
    position: relative;
}
/* Customize the label (the container) */
label.checbox-container {
    position: relative;
    left: 0;
    padding-left: 28px;
    margin-bottom: 8px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


/* Hide the browser's default checkbox */
label.checbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}


/* Create a custom checkbox */
label.checbox-container .checkmark {
    position: absolute;
    top: 2px;
    left: 5px;
    height: 16px;
    width: 16px;
    border: 1px solid #cccccc;
}

/* When the checkbox is checked, add a green background */
.checbox-container input:checked ~ .checkmark {
    background-color: #649430;
}

/* Create the checkmark/indicator (hidden when not checked) */
label.checbox-container .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}


/* Show the checkmark when checked */
.checbox-container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checbox-container .checkmark:after {
    left: 5px;
    top: 1px;
    width: 5px;
    height: 11px;
    border: solid #fff;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

/* Custom checkbox ends */
svg.build {
    fill: #649430;
}

.assemble-it.pt-3.mt-3 {
  padding-top: 0 !important;
  margin-top: 0 !important;
}

.power-plus + .assemble-it.pt-3.mt-3 {
  padding-top: 1rem !important;
  margin-top: 1rem !important;
  border-top: 1px dotted #ccc;
  max-width: 100%;
  width: 100%;
  border: solid 1px #ccc;
  margin: 15px 0 0 0;
}

.assemble-it #serviceNotAdded svg {
  position: relative;
  top: 2px;
}

.assemble-it + div a {
  display: flex;
  flex-direction: column;
}
/** New email signup Styles starts here **/
.email-signup {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem 0.1rem;
  border-top: 1px solid #333333;
  background-color: #ffffff;
  line-height: normal;
}

.email-signup h3 {
  font: normal 700 20px / normal  'Roboto', sans-serif;
}

.email-signup h4 {
  font: normal 400 14px / normal 'Roboto', sans-serif;
  color: #000000;
}

.email-signup form {
  max-width: 706px;
  margin: 0 auto;
  padding: 0 1.5rem;
}

.signup-btn {
  max-width: 100% !important;
  color: #fff;
  background-color: #d20000;
  border-radius: 4px;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 40px;
}

.email-signup .form-control {
  font-weight: normal;
  font-size: 16px;
  height: 40px;
}

.email-signup .form-control:focus {
  color: #333333 !important;
  background-color: #fff !important;
  border-color: #333333 !important;
  -webkit-box-shadow: 0 0 0 0.04rem #333333 !important;
  box-shadow: 0 0 0 0.04rem #333333 !important;
}

.form-group.sm-input {
  display: inline-flex;
  flex-direction: row;
  width: calc(50% - 5px);
}

/*.lg-input {
  margin: 0 4px 1rem 4px;
}*/


.error-block {
  position: absolute;
  display: none;
  top: 51px;
  border: 1px solid #d20000;
  color: #d20000;
  font-size: 16px;
  text-align: left;
  padding: 10px 5px;
  border-radius: 4px;
  z-index: 1000;
  background: #fff;
  left: 0px;
  width: 210px;
}

.error-block svg {
  position: relative;
  top: 3px;
  margin-right: 0.3em;
}

.error-arrow::before {
  position: absolute;
  width: 16px;
  height: 16px;
  border-top: solid 1px #d20000;
  border-right: solid 1px #d20000;
  transform: rotate(-45deg);
  /* right: 0; */
  top: -9px;
  content: "";
  z-index: 101;
  background: #fff;
  left: 25px;
}

#email-error.error-block {
  width: 275px;
  left: -3px;
}

#lname-error.error-block {
  left: -54px;
}

#lname-error.error-block.error-arrow::before {
  left: 128px;
}

.email-success h5 {
  font: normal 700 20px / normal  'Roboto', sans-serif;
}

.email-success p {
  font-size: 14px;
}

.email-success {
  padding: 0 1rem;
}

@media screen and (min-width: 660px) {
  .email-signup .form-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0 auto;
  }

  .error-block {
    width: 275px;
  }

  #lname-error.error-block,
  #fname-error.error-block {
    width: 210px;
    left: 0;
  }

  #lname-error.error-block.error-arrow::before {
    left: 25px;
  }

  form .form-wrapper .lg-input {
    width: 320px;
    margin: 0;
  }

  .signup-btn {
    width: 120px !important;
  }

  .form-group {
    margin-right: 0.5rem !important;
  }

  .sm-input {
    max-width: 120px;
  }
}

/*******************/
/*BOPIS 3.0 STYLES*/

.local-store-price {
  line-height: normal;
}

.green,
.green:hover {
  color: #649430;
}

.fulfillment-options,
.fulfillment-notselect {
  width: 100%;
  color: #333;
  max-width: 350px;
}

.fulfillment-options-livebee.p-0.m-0.pt-2.pb-2 {
    visibility: hidden;
    max-height: 0px;
}

.fulfillment-options .scenario ul {
  list-style: none;
  margin: 0;
  padding: 0 0 15px 0;
  font: normal 13px 'Roboto', sans-serif;
}

.f-option {
  margin-top: 0.5em;
  border: solid 1px #ccc;
  line-height: 18px;
}

.f-option.selected {
  border-color: #649430;
}

.f-option .option-type,
.fulfillment-notselect .option-content {
  font: bold 16px 'Roboto', sans-serif;
  padding-top: 2px;
}

.f-option .promo,
.f-option .location-desc,
.f-option .expectation,
.default-expectation,
.f-option .check-availability,
.f-option .notice,
.fulfillment-notselect .promo,
.fulfillment-notselect .location-desc,
.fulfillment-notselect .expectation,
.fulfillment-notselect .check-availability,
.fulfillment-notselect .notice {
  font: normal 11px 'Roboto', sans-serif;
  line-height: 16px;
}

.f-option .promo {
  font-weight: bold;
}

.f-option .location-desc a,
.f-option .check-availability a,
.fulfillment-notselect .location-desc a {
  color: #005EBD;
  text-decoration: underline;
}

.f-option [type="radio"]:checked+label,
.f-option [type="radio"]:not(:checked)+label {
  display: block;
  padding: 10px 10px 15px 35px;
  margin: 0;
}

.f-option [type="radio"]:checked+label:before,
.f-option [type="radio"]:not(:checked)+label:before {
  top: 12px;
  left: 12px;
}

.f-option [type="radio"]:checked+label:after,
.f-option [type="radio"]:not(:checked)+label:after {
  top: 16px;
  left: 16px;
}

/*styles for fulfillment options with multiple sub-choices*/
.f-option.split [type="radio"]:checked+label,
.f-option.split [type="radio"]:not(:checked)+label {
  padding: 10px 10px 15px 15px;
}

.f-option.split [type="radio"]:checked+label:before,
.f-option.split [type="radio"]:not(:checked)+label:before {
  display: none;
}

.f-option.split [type="radio"]:checked+label:after,
.f-option.split [type="radio"]:not(:checked)+label:after {
  display: none;
}

/*styles for sub-choices*/
.f-option.split .sub-option-content {
  padding-top: 5px;
}

.f-option.split .sub-option-content [type="radio"]:checked+label:before,
.f-option.split .sub-option-content [type="radio"]:not(:checked)+label:before {
  display: block;
  left: -2px;
  top: 8px;
}

.f-option.split .sub-option-content [type="radio"]:checked+label:after,
.f-option.split .sub-option-content [type="radio"]:not(:checked)+label:after {
  display: block;
  left: 2px;
  top: 12px;
}

.f-option.split .sub-option-content label {
  font: normal 11px 'Roboto', sans-serif;
  padding-left: 20px !important;
}

/*END BOPIS 3.0 STYLES*/
/**********************/
.option-content-header{
  padding: 10px 10px 0 15px;
}

/** Fullfilment Modal Redesign **/

.fullscreen-modal.fulfillment-modal .modal-dialog {
    border: 1px solid #ccc;
}

.fullscreen-modal.fulfillment-modal .modal-dialog,
.fullscreen-modal.fulfillment-modal .modal-header {
    max-width: 768px;
    height: auto;
}

.fulfilment-header-content {
    padding: 65px 15px 15px 15px;
    color: #000;
    text-align: left;
}

.mobile-move .fulfilment-header-content {
    padding: 0;
}

.fullscreen-modal.fulfillment-modal .modal-header {
    position: relative;
}

.fullscreen-modal.fulfillment-modal .mobile-move .modal-header {
    height: 55px;
}

.fulfilment-header-content .error,
.fulfilment-header-content .row {
    padding-left: 20px;
}

.fulfilment-header-content button.green-border {
    max-width: 130px !important;
}

.fulfilment-header-content h3 {
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 21px
}

.fulfilment-header-content .additional-options {
    padding-top: 1rem;
    border-top: 1px solid #ccc;
    margin-left: 20px;
}

.avail-options {
    padding-bottom: 1rem;
}

.additional-options .row {
    padding: 1rem 0;
    border-bottom: 1px dotted #ccc;
}

.additional-options .row:last-child {
    border-bottom: none;
}

/** CASE PACK STYLES **/
.casepack {
  margin: 1em 0;
}

.shopping-cart .casepack,
.shopping-cart .egiftcard-details {
  position: relative;
  left: 108px;
  bottom: 17px;
}

.casepack--radio-wrapper {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px 10px 5px 5px;
  display: inline-block;
}

.casepack--radio-wrapper:first-of-type {
  margin-right: 0.8em;
}

.casepack--radio-wrapper.selected {
  border: 1px solid #649430;
}

.casepack input[type=radio] {
  opacity: 0;
  position: absolute;
  width: 0;
  height: 0;
  left: -10px;
  line-height: 0;
  display: block;
}

.casepack input[type=radio]+label {
  position: relative;
  cursor: pointer;
  font-weight: 700;
  color: #000;
  margin-bottom: 0;
  line-height: 16px;
}

.casepack input[type=radio]+label::before {
  content: "";
  position: absolute;
  left: 0px;
  /* Fixed alignment in some browsers. */
  top: 0px;
  border-radius: 50%;
  border: 1px solid #ccc;
  width: 15px;
  height: 15px;
}

.casepack input[type=radio]+label::after {
  content: "";
  position: absolute;
  left: 3px;
  top: 3px;
  border-radius: 50%;
  width: 9px;
  height: 9px;
}

.casepack input[type=radio]:checked+label::after {
  background: #649430;
}
.cart-links {
  position: relative;
  z-index: 2;
}
@media screen and (-ms-high-contrast: active) {
  .casepack input[type=radio]:checked+label::after {
    background: WindowText;
  }
}

.casepack input[type=radio]:focus+label::before {
  outline: 1px auto #000;
}

@media screen and (max-width: 991px) {

  .shopping-cart .casepack,
  .shopping-cart .egiftcard-details {
    padding-left: 0.5rem;
    left: 0px;
    bottom: 0;
  }
}


.shopping-cart .f-option.unavailable {
    background: #f3f3f3;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding: 1em .5em;
    border: 1px solid #ccc;
    margin-bottom: .5em;
    display: flex;
}

.shopping-cart .fulfillment-unavailable{
    padding-left: 5px;
}
.security_leftalign.anti_virus_cont,
.anti_virus_cont{
    margin: 1rem auto;
}
/*ECOM-36763*/
/*Bootstrap upgrade fixes**/
.facetSelect .nodisplay{
    visibility: hidden;
}
[tabindex="-1"]:focus{
  outline: 0 !important;
}
[type=search]::-webkit-search-cancel-button,[type=search]::-webkit-search-decoration {
  -webkit-appearance: none
}

ul.s-list {
  padding-left: 0;
  margin-bottom: 0 !important;
}

ul.s-list li {
  list-style-type: none;
  padding-left: 0;
  padding: 8px 0;
}

ul.s-list li:hover {
  border-top: 1px dotted #ccc;
  border-bottom: 1px dotted #ccc;
  margin: -1px 0;
}

.search-overlay ul.list-box li a {
  color: #000;
}

.category-overlay ul li:first-child:not(.category-tree--sub li:first-child) {
  padding-bottom: 0;
}

.category-tree--sub li:first-child {
  padding-top: 17px;
}

.filter-search--box, .narrowBy-search--box {
  position: relative;
}

.filter-search--box .icon-md-search, .narrowBy-search--box .icon-md-search{
  position: absolute;
  left: 5px;
  top: 5px;
  height: 24px;
  width: 24px;
}

.filter-search--box input[type=search],  .narrowBy-search--box input[type=search]{
  padding: 1em 2em 1em 2em;
  border: 1px solid #808080 !important;
  border-radius: 4px;
  height: 36px;
  font-size: 14px;
  line-height: normal;
}

.filter-search--box .clear-wrapper, .narrowBy-search--box .clear-wrapper{
  position: absolute;
  top: 6px;
  right: 3px;
  background: #fff;
  width: auto;
  max-width: none !important;
  height: auto;
  display: none;
}

.filter-search--box .clear-wrapper svg, .narrowBy-search--box .clear-wrapper svg {
  height: 22px;
  width: 22px;
  fill: #cccccc;
}

.filter-search--box .filter-results {
  background: #fff;
  padding: 14px;
  position: absolute;
  right: 0;
  top: 36px;
  width: 100%;
  font-family: 'Roboto', sans-serif;
  line-height: 21px;
  font-size: 14px;
  border: 1px #cccccc solid;
  border-radius: 4px;
  z-index: 2;
  display: none;
}

ul.s-list {
  padding-left: 0;
  margin-bottom: 0 !important;
}

ul.s-list li {
  list-style-type: none;
  padding-left: 0;
  padding: 8px 0;
}

ul.s-list li:hover {
  border-top: 1px dotted #ccc;
  border-bottom: 1px dotted #ccc;
  margin: -1px 0;
}

.search-overlay ul.list-box li a {
  color: #000;
}

.category-overlay ul li:first-child:not(.category-tree--sub li:first-child) {
  padding-bottom: 0;
}

.category-tree--sub li:first-child {
  padding-top: 17px;
}
.fulfillment-options .f-option input[type="radio"]:focus {
  outline: 0;
}
/** AEM Fixes **/
.close-thin {
  right: 17px;
  position: absolute;
  top: 10px;
  font: normal 14px 'Roboto', sans-serif;
  color: #000;
  text-shadow: none;
  line-height: 21px;
  background-image: url(../../images/gray-cancel.png);
  background-repeat: no-repeat;
  background-size: 22px;
  width: 22px;
  height: 22px;
}

/** plp fixes **/
.body-with-search .container--wcs .tsc-cookie-banner {
  z-index: 999;
}

.body-with-search .compare-drawer {
  z-index: 1000;
}

.body-with-search.categorymenu-body--scroll-locked .compare-drawer,
.body-with-search.scroll-locked .compare-drawer,
.tsc-body--scroll-locked .compare-drawer,
.button-modal-body--scroll-locked .compare-drawer {
  z-index: 999;
}

/**hover btn**/
.btn-hover-wrapper {
  position: absolute;
  left: 0;
  bottom: -30px;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 11px;
}

.hover-btn-v2 {
  max-width: none !important;
  background: none;
  height: 20px;
  padding: 0 0 0 5px;
  border-radius: 0;
  left: 0;
  color: #000;
  font-size: 11px;
}

.s7zoomview canvas,
#loginOrRegisterModal * {
  user-select: none !important;
}

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

html.fsfb.fsfb-relbody head ~ body{
  position: static !Important;
}

.plp-atc-btn {
  width: 100% !important;
  max-width: 100% !important;
  background-color: rgba(100, 148, 48, 1);
  height: 47px;
  font-weight: 700;
  font-size: 16px;
  margin-top: auto;
}


.navbar-mobile .tsc-logo-svg {
  width: 130px;
  height: 33px;
  cursor: pointer;
  position: relative;
  top: 4px;
}


.modal .gigya-login-providers-container {
  position: static;
  transform: translateY(0);
}

.modal .gigya-login-providers {
  height: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal .gigya-login-provider-row {
  margin-bottom: 15px !important;
  padding: 0;
}
/** CLS score - Aspect ratio **/
body.body-aem-header.body-with-search * {
    aspect-ratio: auto;
}

/** paypal PAylater changes **/
#papbutton .paypal-buttons,
#palaterbutton .paypal-buttons,
#papATCbutton .paypal-buttons,
#palaterATCbutton .paypal-buttons {
    min-width: 100%;
    max-width: 100%;
    min-height: 40px;

}

#googlePaySection,
#applePaySection,
#googlePaySection_atc,
#applePaySection_atc {
    margin: 0 !important;
    padding: 0 !important;
}


#shopping-cart #paypalSection,
#atcPapbutton {
    margin: 0 !important;
    padding-right: 0px !important;
}

#paylaterSection,
#atcPaylaterSection {
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin: 0 !important;
}

.add-to-cart-redesign .alternate-payments {
    column-gap: 7px;
}

.alternate-payments {
    column-gap: 10px;
}

.payment-page .alternate-payments>div:not(#paypalPayLaterMessage):not(#subscription_disclaimer) {
    flex: 1 1 0px;
    padding-right: 0 !important;
    min-width: 100px;
    max-width: 100px;
    padding-bottom: 0 !important;
}

.payment-page .alternate-payments {
    column-gap: 8px;
}

#gPayButton,
#applePayButton,
#gPayButton_atc,
#applePayButton_atc {
    background-size: 50px;
}

.progress-bar p{margin:0}
.progress-striped .progress-bar{background-color: rgb(200, 16, 46);background-image:none;}
.progress-striped .progress-bar.progress-bar-medium{background-color: #9d7d33;}
.progress-striped .progress-bar.progress-bar-veryStrong,.progress-striped .progress-bar-storng{background-color: #649430;}
.progress{height: 12px; border-radius: 0;}
#passwordStrengthSection{margin-top: 10px;}
.ShowMessages{margin-top: 10px;}
.ShowMessages span{width:15px;display:inline-block;}
.loginOrRegisterModal_wishlistNew #passwordStrengthSection{margin-right: 0.5rem !important;}
.tsc-primary-btn.disabled#WC_AccountDisplay_links_3:hover{background-color: #d5d5d5; border: none; color: #919191; background-image: none !important; cursor: default;}

.progress-striped .progress-bar.progress-bar-medium{background-color: #E87214;}
.progress-striped .progress-bar.progress-bar-veryStrong,.progress-striped .progress-bar-storng{background-color: #649430;}
.progress{height: 12px; border-radius: 0;background: transparent;margin: 0;padding: 0;border: 1px solid #CCCCCC;}
.progress-bar{background-color:#CE0E2D;height: auto;}
.ShowMessages p{color:#808080;font-size:12;margin-bottom: 5px}
.ShowMessages .correct{color:#649430;}
.ShowMessages .incorrect{color:#000;}
.progress{height: 12px; border-radius: 0;background: transparent; margin-bottom: 10px;padding: 0;border: 1px solid #CCCCCC;}

#nc-dashboard .main-col .progress.progress-striped .progress-bar p{margin:0}
#nc-dashboard .main-col .progress.progress-striped .progress-bar{background-color: rgb(200, 16, 46);background-image:none;}
#nc-dashboard .main-col .progress.progress-striped .progress-bar.progress-bar-medium{background-color: #E87214;}
#nc-dashboard .main-col .progress.progress-striped .progress-bar.progress-bar-veryStrong,#nc-dashboard .main-col .progress.progress-striped .progress-bar.progress-bar-storng{background-color: #649430;}
#nc-dashboard .main-col .progress.progress-striped{height: 12px; border-radius: 0;background: transparent;margin-bottom: 10px;padding: 0;border: 1px solid #CCCCCC;}
#nc-dashboard .main-col .progress.progress-striped .progress-bar{background-color:#CE0E2D;height: auto;}
#nc-dashboard .main-col .ShowMessages p{color:#808080;font-size:12px;margin-bottom: 5px}
#nc-dashboard .main-col .ShowMessages .correct{color:#649430;}
#nc-dashboard .main-col .ShowMessages .incorrect{color:#000;}

.pass-font12{font-size:12px;}

.birthday-select-holder .customized-select:after{display:none;}

#nc-dashboard .main-col .progress.progress-striped{
	height: 12px;
	border-radius: 0;
	background: 0 0;
	margin-bottom: 10px;
	padding: 0;
	border: 1px solid #CCC;
	margin: 0 0 10px 0;
	transform: rotate(0deg);
	width: auto;
}

#nc-dashboard .main-col .progress.progress-striped:after{
	display:none;
}

#nc-dashboard .main-col .progress.progress-striped .progress-bar{
	background-image: none;
	position: relative;
	border-color: transparent;
	border-width: 0;
}

.ShowMessages span {
	display:inline-block;
	width:18px;
}
.gptPLP, .gptPLP iframe {
	margin: 1rem auto;
	display: flex;
	justify-content: center;
}
.gptPLPTop, .gptPLPTop iframe {
	margin: 0.5rem auto 0 auto;
	display: flex;
	justify-content: center;
}
.gptPLPTop.gptPLPmWeb,
.gptPLPTop.gptPLPmWeb iframe{
  margin: 0.5rem auto;
}
@media screen and (max-width: 1279px){
    .gptPLPTop, .gptPLPTop iframe{
        margin: 1rem auto 0;
    }
}
#plp-container-custom-slot-1 .contentRecommendationWidget,
#plp-container-custom-slot-1 div[id^=contentCarouselWidget_slot-1_],
#plp-container-custom-slot-1 #RelatedSearchContain{
  display: none;
}

#circular-container #flipp-container {
text-align: justify;
}
